(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.bM.ab === region.cb.ab)
	{
		return 'on line ' + region.bM.ab;
	}
	return 'on lines ' + region.bM.ab + ' through ' + region.cb.ab;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dC,
		impl.ef,
		impl.d8,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		bi: func(record.bi),
		bO: record.bO,
		bE: record.bE
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.bi;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.bO;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.bE) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dC,
		impl.ef,
		impl.d8,
		function(sendToApp, initialModel) {
			var view = impl.eg;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dC,
		impl.ef,
		impl.d8,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.bK && impl.bK(sendToApp)
			var view = impl.eg;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.b5);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.ed) && (_VirtualDom_doc.title = title = doc.ed);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.dV;
	var onUrlRequest = impl.dW;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		bK: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.cG === next.cG
							&& curr.cn === next.cn
							&& curr.cB.a === next.cB.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		dC: function(flags)
		{
			return A3(impl.dC, flags, _Browser_getUrl(), key);
		},
		eg: impl.eg,
		ef: impl.ef,
		d8: impl.d8
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { dx: 'hidden', di: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { dx: 'mozHidden', di: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { dx: 'msHidden', di: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { dx: 'webkitHidden', di: 'webkitvisibilitychange' }
		: { dx: 'hidden', di: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		cO: _Browser_getScene(),
		c3: {
			c6: _Browser_window.pageXOffset,
			c7: _Browser_window.pageYOffset,
			bY: _Browser_doc.documentElement.clientWidth,
			cl: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		bY: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		cl: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			cO: {
				bY: node.scrollWidth,
				cl: node.scrollHeight
			},
			c3: {
				c6: node.scrollLeft,
				c7: node.scrollTop,
				bY: node.clientWidth,
				cl: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			cO: _Browser_getScene(),
			c3: {
				c6: x,
				c7: y,
				bY: _Browser_doc.documentElement.clientWidth,
				cl: _Browser_doc.documentElement.clientHeight
			},
			dp: {
				c6: x + rect.left,
				c7: y + rect.top,
				bY: rect.width,
				cl: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.dr.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.dr.b, xhr)); });
		$elm$core$Maybe$isJust(request.ee) && _Http_track(router, xhr, request.ee.a);

		try {
			xhr.open(request.cw, request.c1, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.c1));
		}

		_Http_configureRequest(xhr, request);

		request.b5.a && xhr.setRequestHeader('Content-Type', request.b5.a);
		xhr.send(request.b5.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.ck; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.cX.a || 0;
	xhr.responseType = request.dr.d;
	xhr.withCredentials = request.c9;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		c1: xhr.responseURL,
		d5: xhr.status,
		d6: xhr.statusText,
		ck: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			d3: event.loaded,
			ai: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			dZ: event.loaded,
			ai: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}



// BYTES

function _Bytes_width(bytes)
{
	return bytes.byteLength;
}

var _Bytes_getHostEndianness = F2(function(le, be)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(new Uint8Array(new Uint32Array([1]))[0] === 1 ? le : be));
	});
});


// ENCODERS

function _Bytes_encode(encoder)
{
	var mutableBytes = new DataView(new ArrayBuffer($elm$bytes$Bytes$Encode$getWidth(encoder)));
	$elm$bytes$Bytes$Encode$write(encoder)(mutableBytes)(0);
	return mutableBytes;
}


// SIGNED INTEGERS

var _Bytes_write_i8  = F3(function(mb, i, n) { mb.setInt8(i, n); return i + 1; });
var _Bytes_write_i16 = F4(function(mb, i, n, isLE) { mb.setInt16(i, n, isLE); return i + 2; });
var _Bytes_write_i32 = F4(function(mb, i, n, isLE) { mb.setInt32(i, n, isLE); return i + 4; });


// UNSIGNED INTEGERS

var _Bytes_write_u8  = F3(function(mb, i, n) { mb.setUint8(i, n); return i + 1 ;});
var _Bytes_write_u16 = F4(function(mb, i, n, isLE) { mb.setUint16(i, n, isLE); return i + 2; });
var _Bytes_write_u32 = F4(function(mb, i, n, isLE) { mb.setUint32(i, n, isLE); return i + 4; });


// FLOATS

var _Bytes_write_f32 = F4(function(mb, i, n, isLE) { mb.setFloat32(i, n, isLE); return i + 4; });
var _Bytes_write_f64 = F4(function(mb, i, n, isLE) { mb.setFloat64(i, n, isLE); return i + 8; });


// BYTES

var _Bytes_write_bytes = F3(function(mb, offset, bytes)
{
	for (var i = 0, len = bytes.byteLength, limit = len - 4; i <= limit; i += 4)
	{
		mb.setUint32(offset + i, bytes.getUint32(i));
	}
	for (; i < len; i++)
	{
		mb.setUint8(offset + i, bytes.getUint8(i));
	}
	return offset + len;
});


// STRINGS

function _Bytes_getStringWidth(string)
{
	for (var width = 0, i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		width +=
			(code < 0x80) ? 1 :
			(code < 0x800) ? 2 :
			(code < 0xD800 || 0xDBFF < code) ? 3 : (i++, 4);
	}
	return width;
}

var _Bytes_write_string = F3(function(mb, offset, string)
{
	for (var i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		offset +=
			(code < 0x80)
				? (mb.setUint8(offset, code)
				, 1
				)
				:
			(code < 0x800)
				? (mb.setUint16(offset, 0xC080 /* 0b1100000010000000 */
					| (code >>> 6 & 0x1F /* 0b00011111 */) << 8
					| code & 0x3F /* 0b00111111 */)
				, 2
				)
				:
			(code < 0xD800 || 0xDBFF < code)
				? (mb.setUint16(offset, 0xE080 /* 0b1110000010000000 */
					| (code >>> 12 & 0xF /* 0b00001111 */) << 8
					| code >>> 6 & 0x3F /* 0b00111111 */)
				, mb.setUint8(offset + 2, 0x80 /* 0b10000000 */
					| code & 0x3F /* 0b00111111 */)
				, 3
				)
				:
			(code = (code - 0xD800) * 0x400 + string.charCodeAt(++i) - 0xDC00 + 0x10000
			, mb.setUint32(offset, 0xF0808080 /* 0b11110000100000001000000010000000 */
				| (code >>> 18 & 0x7 /* 0b00000111 */) << 24
				| (code >>> 12 & 0x3F /* 0b00111111 */) << 16
				| (code >>> 6 & 0x3F /* 0b00111111 */) << 8
				| code & 0x3F /* 0b00111111 */)
			, 4
			);
	}
	return offset;
});


// DECODER

var _Bytes_decode = F2(function(decoder, bytes)
{
	try {
		return $elm$core$Maybe$Just(A2(decoder, bytes, 0).b);
	} catch(e) {
		return $elm$core$Maybe$Nothing;
	}
});

var _Bytes_read_i8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getInt8(offset)); });
var _Bytes_read_i16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getInt16(offset, isLE)); });
var _Bytes_read_i32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getInt32(offset, isLE)); });
var _Bytes_read_u8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getUint8(offset)); });
var _Bytes_read_u16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getUint16(offset, isLE)); });
var _Bytes_read_u32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getUint32(offset, isLE)); });
var _Bytes_read_f32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getFloat32(offset, isLE)); });
var _Bytes_read_f64 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 8, bytes.getFloat64(offset, isLE)); });

var _Bytes_read_bytes = F3(function(len, bytes, offset)
{
	return _Utils_Tuple2(offset + len, new DataView(bytes.buffer, bytes.byteOffset + offset, len));
});

var _Bytes_read_string = F3(function(len, bytes, offset)
{
	var string = '';
	var end = offset + len;
	for (; offset < end;)
	{
		var byte = bytes.getUint8(offset++);
		string +=
			(byte < 128)
				? String.fromCharCode(byte)
				:
			((byte & 0xE0 /* 0b11100000 */) === 0xC0 /* 0b11000000 */)
				? String.fromCharCode((byte & 0x1F /* 0b00011111 */) << 6 | bytes.getUint8(offset++) & 0x3F /* 0b00111111 */)
				:
			((byte & 0xF0 /* 0b11110000 */) === 0xE0 /* 0b11100000 */)
				? String.fromCharCode(
					(byte & 0xF /* 0b00001111 */) << 12
					| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
					| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
				)
				:
				(byte =
					((byte & 0x7 /* 0b00000111 */) << 18
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 12
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
						| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
					) - 0x10000
				, String.fromCharCode(Math.floor(byte / 0x400) + 0xD800, byte % 0x400 + 0xDC00)
				);
	}
	return _Utils_Tuple2(offset, string);
});

var _Bytes_decodeFailure = F2(function() { throw 0; });



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.dH) { flags += 'm'; }
	if (options.dh) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;
var $author$project$Main$ChangedUrl = function (a) {
	return {$: 11, a: a};
};
var $author$project$Main$ClickedLink = function (a) {
	return {$: 12, a: a};
};
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.f) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.h);
		} else {
			var treeLen = builder.f * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.i) : builder.i;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.f);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.h);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{i: nodeList, f: (len / $elm$core$Array$branchFactor) | 0, h: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {ch: fragment, cn: host, cz: path, cB: port_, cG: protocol, Q: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Main$Logged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$NotLogged = {$: 1};
var $author$project$Main$CaseLoaded = function (a) {
	return {$: 14, a: a};
};
var $author$project$Main$CasesMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$ForgotPassword = function (a) {
	return {$: 5, a: a};
};
var $author$project$Route$Home = {$: 0};
var $author$project$Main$HomeLoaded = function (a) {
	return {$: 13, a: a};
};
var $author$project$Main$Loaded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$Login = function (a) {
	return {$: 4, a: a};
};
var $author$project$Route$Login = {$: 1};
var $author$project$Main$LogoutCompleted = function (a) {
	return {$: 10, a: a};
};
var $author$project$Main$NewCase = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$SetTime = function (a) {
	return {$: 17, a: a};
};
var $author$project$Main$TransitioningFrom = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.d5));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $author$project$Main$getPage = function (pageState) {
	if (!pageState.$) {
		var page = pageState.a;
		return page;
	} else {
		var page = pageState.a;
		return page;
	}
};
var $author$project$Page$ForgotPassword$Model = F3(
	function (email, error, sending) {
		return {X: email, cc: error, ah: sending};
	});
var $author$project$Page$ForgotPassword$initModel = A3($author$project$Page$ForgotPassword$Model, '', '', false);
var $author$project$Page$Login$Model = F6(
	function (email, password, errorEmail, errorPassword, error, sending) {
		return {X: email, cc: error, ax: errorEmail, ay: errorPassword, ag: password, ah: sending};
	});
var $author$project$Page$Login$initModel = A6($author$project$Page$Login$Model, '', '', '', '', '', false);
var $author$project$Page$NewCase$Model = F6(
	function (subject, description, file, error, caseId, sending) {
		return {a$: caseId, V: description, cc: error, Y: file, ah: sending, ak: subject};
	});
var $author$project$Page$NewCase$initModel = A6($author$project$Page$NewCase$Model, '', '', $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, false);
var $author$project$Cases$GotCases = $elm$core$Basics$identity;
var $author$project$Type$Case$Case = F8(
	function (id, title, authorName, authorInitials, status, history, created, updated) {
		return {db: authorInitials, dc: authorName, dm: created, dy: history, co: id, d4: status, ed: title, c0: updated};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$DateTime$deadEndToString = function (_v0) {
	var problem = _v0.dY;
	if (problem.$ === 12) {
		var message = problem.a;
		return message;
	} else {
		return 'Expected a date in ISO 8601 format (YYYY-MM-dd HH:mm:ss)';
	}
};
var $author$project$DateTime$DateTimeParts = F6(
	function (year, month, day, hour, minute, second) {
		return {U: day, aA: hour, aJ: minute, ad: month, aR: second, aq: year};
	});
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {b8: col, dl: contextStack, dY: problem, cL: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.cL, s.b8, x, s.c));
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{b8: 1, c: s.c, d: s.d, b: s.b + 1, cL: s.cL + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{b8: s.b8 + 1, c: s.c, d: s.d, b: newOffset, cL: s.cL, a: s.a}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b, s1.b, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$mapChompedString = $elm$parser$Parser$Advanced$mapChompedString;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$DateTime$int2 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(0),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $author$project$DateTime$int4 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.cL, s.b8, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{b8: newCol, c: s.c, d: s.d, b: newOffset, cL: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $author$project$DateTime$parser = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($author$project$DateTime$DateTimeParts),
						A2(
							$elm$parser$Parser$ignorer,
							$author$project$DateTime$int4,
							$elm$parser$Parser$symbol('-'))),
					A2(
						$elm$parser$Parser$ignorer,
						$author$project$DateTime$int2,
						$elm$parser$Parser$symbol('-'))),
				A2(
					$elm$parser$Parser$ignorer,
					$author$project$DateTime$int2,
					$elm$parser$Parser$symbol('T'))),
			A2(
				$elm$parser$Parser$ignorer,
				$author$project$DateTime$int2,
				$elm$parser$Parser$symbol(':'))),
		A2(
			$elm$parser$Parser$ignorer,
			$author$project$DateTime$int2,
			$elm$parser$Parser$symbol(':'))),
	$author$project$DateTime$int2);
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {b8: col, dY: problem, cL: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.cL, p.b8, p.dY);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{b8: 1, c: _List_Nil, d: 1, b: 0, cL: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $author$project$DateTime$fromStringToParts = function (stringDateTime) {
	var stringDateTimeCompatible = A3($elm$core$String$replace, ' ', 'T', stringDateTime);
	return A2(
		$elm$core$Result$mapError,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$map($author$project$DateTime$deadEndToString),
			$elm$core$String$join('; ')),
		A2($elm$parser$Parser$run, $author$project$DateTime$parser, stringDateTimeCompatible));
};
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0;
	return rd;
};
var $justinmimbs$time_extra$Time$Extra$dateToMillis = function (date) {
	var daysSinceEpoch = $justinmimbs$date$Date$toRataDie(date) - 719163;
	return daysSinceEpoch * 86400000;
};
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.bM, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		U: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		ad: month,
		aq: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).U;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).ad;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).aq;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $justinmimbs$time_extra$Time$Extra$timeFromClock = F4(
	function (hour, minute, second, millisecond) {
		return (((hour * 3600000) + (minute * 60000)) + (second * 1000)) + millisecond;
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $justinmimbs$time_extra$Time$Extra$timeFromPosix = F2(
	function (zone, posix) {
		return A4(
			$justinmimbs$time_extra$Time$Extra$timeFromClock,
			A2($elm$time$Time$toHour, zone, posix),
			A2($elm$time$Time$toMinute, zone, posix),
			A2($elm$time$Time$toSecond, zone, posix),
			A2($elm$time$Time$toMillis, zone, posix));
	});
var $justinmimbs$time_extra$Time$Extra$toOffset = F2(
	function (zone, posix) {
		var millis = $elm$time$Time$posixToMillis(posix);
		var localMillis = $justinmimbs$time_extra$Time$Extra$dateToMillis(
			A2($justinmimbs$date$Date$fromPosix, zone, posix)) + A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix);
		return ((localMillis - millis) / 60000) | 0;
	});
var $justinmimbs$time_extra$Time$Extra$posixFromDateTime = F3(
	function (zone, date, time) {
		var millis = $justinmimbs$time_extra$Time$Extra$dateToMillis(date) + time;
		var offset0 = A2(
			$justinmimbs$time_extra$Time$Extra$toOffset,
			zone,
			$elm$time$Time$millisToPosix(millis));
		var posix1 = $elm$time$Time$millisToPosix(millis - (offset0 * 60000));
		var offset1 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix1);
		if (_Utils_eq(offset0, offset1)) {
			return posix1;
		} else {
			var posix2 = $elm$time$Time$millisToPosix(millis - (offset1 * 60000));
			var offset2 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix2);
			return _Utils_eq(offset1, offset2) ? posix2 : posix1;
		}
	});
var $justinmimbs$time_extra$Time$Extra$partsToPosix = F2(
	function (zone, _v0) {
		var year = _v0.aq;
		var month = _v0.ad;
		var day = _v0.U;
		var hour = _v0.aA;
		var minute = _v0.aJ;
		var second = _v0.aR;
		var millisecond = _v0.cx;
		return A3(
			$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
			zone,
			A3($justinmimbs$date$Date$fromCalendarDate, year, month, day),
			A4(
				$justinmimbs$time_extra$Time$Extra$timeFromClock,
				A3($elm$core$Basics$clamp, 0, 23, hour),
				A3($elm$core$Basics$clamp, 0, 59, minute),
				A3($elm$core$Basics$clamp, 0, 59, second),
				A3($elm$core$Basics$clamp, 0, 999, millisecond)));
	});
var $author$project$DateTime$toMonth = function (month) {
	switch (month) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		case 12:
			return 11;
		default:
			return 0;
	}
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$DateTime$zone = $elm$time$Time$utc;
var $author$project$DateTime$partsToPosix = function (parts) {
	return A2(
		$justinmimbs$time_extra$Time$Extra$partsToPosix,
		$author$project$DateTime$zone,
		{
			U: parts.U,
			aA: parts.aA,
			cx: 0,
			aJ: parts.aJ,
			ad: $author$project$DateTime$toMonth(parts.ad),
			aR: parts.aR,
			aq: parts.aq
		});
};
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$DateTime$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (dateTimeString) {
		var _v0 = $author$project$DateTime$fromStringToParts(dateTimeString);
		if (_v0.$ === 1) {
			var deadEnds = _v0.a;
			return $elm$json$Json$Decode$fail(deadEnds);
		} else {
			var parts = _v0.a;
			return $elm$json$Json$Decode$succeed(
				$author$project$DateTime$partsToPosix(parts));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Type$Case$CaseHistory = F6(
	function (id, author, status, created, message, files) {
		return {aZ: author, dm: created, dw: files, co: id, bi: message, d4: status};
	});
var $author$project$Type$Case$CaseHistoryAuthor = F3(
	function (name, initials, avatar) {
		return {dd: avatar, dD: initials, dI: name};
	});
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Type$Case$authorDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'avatar',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'initials',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Type$Case$CaseHistoryAuthor))));
var $author$project$Type$Case$CaseFile = F2(
	function (name, url) {
		return {dI: name, c1: url};
	});
var $author$project$Type$Case$fileDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'file_url',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'mask',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Type$Case$CaseFile)));
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt = F3(
	function (path, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$at, path, valDecoder),
			decoder);
	});
var $author$project$Type$Case$historyDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'files',
	$elm$json$Json$Decode$list($author$project$Type$Case$fileDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
		_List_fromArray(
			['data', 'message_string']),
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
			_List_fromArray(
				['data', 'date_added']),
			$author$project$DateTime$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
				_List_fromArray(
					['data', 'order_status_id']),
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'data',
					$author$project$Type$Case$authorDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
						_List_fromArray(
							['data', 'incident_history_id']),
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed($author$project$Type$Case$CaseHistory)))))));
var $elm$core$String$trim = _String_trim;
var $author$project$Type$Case$nameDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'lastname',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'firstname',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed(
			F2(
				function (firstName, lastName) {
					return $elm$core$String$trim(firstName + (' ' + lastName));
				}))));
var $author$project$Type$Case$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'date_modified',
	$author$project$DateTime$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'date_added',
		$author$project$DateTime$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'history',
			$elm$json$Json$Decode$list($author$project$Type$Case$historyDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'status_name',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'customer_initials',
					$elm$json$Json$Decode$string,
					A2(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
						$author$project$Type$Case$nameDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'incident_no',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'incident_id',
								$elm$json$Json$Decode$int,
								$elm$json$Json$Decode$succeed($author$project$Type$Case$Case)))))))));
var $author$project$Cases$decoder = A2(
	$elm$json$Json$Decode$field,
	'incidents',
	$elm$json$Json$Decode$list($author$project$Type$Case$decoder));
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {cI: reqs, cV: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.ee;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.cI));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.cV)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					c9: r.c9,
					b5: r.b5,
					dr: A2(_Http_mapExpect, func, r.dr),
					ck: r.ck,
					cw: r.cw,
					cX: r.cX,
					ee: r.ee,
					c1: r.c1
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{c9: false, b5: r.b5, dr: r.dr, ck: r.ck, cw: r.cw, cX: r.cX, ee: r.ee, c1: r.c1}));
};
var $author$project$Api$request = F5(
	function (method, requestUrl, body, expect, maybeAuthorization) {
		return $elm$http$Http$request(
			{
				b5: body,
				dr: expect,
				ck: function () {
					if (!maybeAuthorization.$) {
						var user = maybeAuthorization.a;
						return _List_fromArray(
							[
								A2($elm$http$Http$header, 'Authorization', user.c_ + (' ' + user.cY))
							]);
					} else {
						return _List_Nil;
					}
				}(),
				cw: method,
				cX: $elm$core$Maybe$Nothing,
				ee: $elm$core$Maybe$Nothing,
				c1: requestUrl
			});
	});
var $elm$core$String$append = _String_append;
var $author$project$Api$baseUrl = 'https://moje.avogado.cz/data/public/api/v1/';
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$Api$url = F2(
	function (endpoint, queryParameters) {
		var parameters = $elm$core$List$isEmpty(queryParameters) ? '' : A2(
			$elm$core$String$append,
			'?',
			A2(
				$elm$core$String$join,
				'&',
				A2(
					$elm$core$List$map,
					function (item) {
						return item.a + ('=' + item.b);
					},
					queryParameters)));
		return _Utils_ap(
			$author$project$Api$baseUrl,
			_Utils_ap(endpoint, parameters));
	});
var $author$project$Api$getUserCase = F2(
	function (expect, user) {
		return A5(
			$author$project$Api$request,
			'POST',
			A2($author$project$Api$url, 'getUserCase', _List_Nil),
			$elm$http$Http$emptyBody,
			expect,
			$elm$core$Maybe$Just(user));
	});
var $author$project$Cases$load = function (user) {
	return A2(
		$author$project$Api$getUserCase,
		A2($elm$http$Http$expectJson, $elm$core$Basics$identity, $author$project$Cases$decoder),
		user);
};
var $author$project$Page$Case$LoadModel = F2(
	function (maybeCaseDetail, timeNow) {
		return {cv: maybeCaseDetail, J: timeNow};
	});
var $author$project$Page$Case$caseDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'incident',
			A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $author$project$Type$Case$decoder)),
			A2(
			$elm$json$Json$Decode$field,
			'message',
			$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing))
		]));
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $author$project$Api$jsonResolver = function (decoder) {
	return $elm$http$Http$stringResolver(
		function (response) {
			switch (response.$) {
				case 0:
					var url_ = response.a;
					return $elm$core$Result$Err(
						$elm$http$Http$BadUrl(url_));
				case 1:
					return $elm$core$Result$Err($elm$http$Http$Timeout);
				case 2:
					return $elm$core$Result$Err($elm$http$Http$NetworkError);
				case 3:
					var metadata = response.a;
					return $elm$core$Result$Err(
						$elm$http$Http$BadStatus(metadata.d5));
				default:
					var body = response.b;
					var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
					if (!_v1.$) {
						var value = _v1.a;
						return $elm$core$Result$Ok(value);
					} else {
						return $elm$core$Result$Err(
							$elm$http$Http$BadBody(body));
					}
			}
		});
};
var $elm$core$Task$fail = _Scheduler_fail;
var $elm$http$Http$resultToTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{c9: false, b5: r.b5, dr: r.d$, ck: r.ck, cw: r.cw, cX: r.cX, ee: $elm$core$Maybe$Nothing, c1: r.c1});
};
var $author$project$Api$task = F5(
	function (method, requestUrl, body, decoder, maybeAuthorization) {
		return $elm$http$Http$task(
			{
				b5: body,
				ck: function () {
					if (!maybeAuthorization.$) {
						var user = maybeAuthorization.a;
						return _List_fromArray(
							[
								A2($elm$http$Http$header, 'Authorization', user.c_ + (' ' + user.cY))
							]);
					} else {
						return _List_Nil;
					}
				}(),
				cw: method,
				d$: $author$project$Api$jsonResolver(decoder),
				cX: $elm$core$Maybe$Nothing,
				c1: requestUrl
			});
	});
var $author$project$Api$getCaseByIdTask = F3(
	function (body, decoder, user) {
		return A5(
			$author$project$Api$task,
			'POST',
			A2($author$project$Api$url, 'getCaseById', _List_Nil),
			body,
			decoder,
			$elm$core$Maybe$Just(user));
	});
var $elm$http$Http$multipartBody = function (parts) {
	return A2(
		_Http_pair,
		'',
		_Http_toFormData(parts));
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$http$Http$stringPart = _Http_pair;
var $author$project$Page$Case$load = F2(
	function (user, id) {
		return A3(
			$elm$core$Task$map2,
			$author$project$Page$Case$LoadModel,
			A3(
				$author$project$Api$getCaseByIdTask,
				$elm$http$Http$multipartBody(
					_List_fromArray(
						[
							A2(
							$elm$http$Http$stringPart,
							'incident_id',
							$elm$core$String$fromInt(id))
						])),
				$author$project$Page$Case$caseDecoder,
				user),
			$elm$time$Time$now);
	});
var $author$project$Page$Home$Model = F2(
	function (cases, timeNow) {
		return {v: cases, J: timeNow};
	});
var $author$project$Api$getUserCaseTask = F2(
	function (decoder, user) {
		return A5(
			$author$project$Api$task,
			'POST',
			A2($author$project$Api$url, 'getUserCase', _List_Nil),
			$elm$http$Http$emptyBody,
			decoder,
			$elm$core$Maybe$Just(user));
	});
var $author$project$Page$Home$load = function (user) {
	return A3(
		$elm$core$Task$map2,
		$author$project$Page$Home$Model,
		A2($author$project$Api$getUserCaseTask, $author$project$Cases$decoder, user),
		$elm$time$Time$now);
};
var $author$project$Api$logout = F2(
	function (expect, user) {
		return A5(
			$author$project$Api$request,
			'GET',
			A2($author$project$Api$url, 'logout', _List_Nil),
			$elm$http$Http$emptyBody,
			expect,
			$elm$core$Maybe$Just(user));
	});
var $author$project$Main$LogoutResponse = F2(
	function (status, message) {
		return {bi: message, d4: status};
	});
var $author$project$Main$logoutDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'message',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'status',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Main$LogoutResponse)));
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Main$scrollTop = _Platform_outgoingPort(
	'scrollTop',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Main$setError = F2(
	function (error, model) {
		return _Utils_update(
			model,
			{
				aw: $elm$core$Maybe$Just(error),
				g: $author$project$Main$Loaded(
					$author$project$Main$getPage(model.g))
			});
	});
var $author$project$Main$changeRouteTo = F2(
	function (maybeRoute, model) {
		changeRouteTo:
		while (true) {
			var updatedModel = _Utils_update(
				model,
				{ac: maybeRoute});
			var setTimeCmd = A2($elm$core$Task$perform, $author$project$Main$SetTime, $elm$time$Time$now);
			var page = $author$project$Main$getPage(model.g);
			var cmd = $elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						setTimeCmd,
						$author$project$Main$scrollTop(0)
					]));
			var loadNotLogged = function (page_) {
				return _Utils_Tuple2(
					_Utils_update(
						updatedModel,
						{
							g: $author$project$Main$Loaded(page_)
						}),
					cmd);
			};
			var casesCmd = function (user_) {
				return A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Main$CasesMsg,
					$author$project$Cases$load(user_));
			};
			var load = F2(
				function (page_, user_) {
					return _Utils_Tuple2(
						_Utils_update(
							updatedModel,
							{
								g: $author$project$Main$Loaded(page_)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									cmd,
									casesCmd(user_)
								])));
				});
			var transition = F3(
				function (toMsg, task, user_) {
					return _Utils_Tuple2(
						_Utils_update(
							updatedModel,
							{
								g: $author$project$Main$TransitioningFrom(page)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									cmd,
									A2($elm$core$Task$attempt, toMsg, task),
									casesCmd(user_)
								])));
				});
			var _v0 = model.m;
			if (_v0.$ === 1) {
				_v1$2:
				while (true) {
					if (!maybeRoute.$) {
						switch (maybeRoute.a.$) {
							case 1:
								var _v2 = maybeRoute.a;
								return loadNotLogged(
									$author$project$Main$Login($author$project$Page$Login$initModel));
							case 3:
								var _v3 = maybeRoute.a;
								return loadNotLogged(
									$author$project$Main$ForgotPassword($author$project$Page$ForgotPassword$initModel));
							default:
								break _v1$2;
						}
					} else {
						break _v1$2;
					}
				}
				var $temp$maybeRoute = $elm$core$Maybe$Just($author$project$Route$Login),
					$temp$model = updatedModel;
				maybeRoute = $temp$maybeRoute;
				model = $temp$model;
				continue changeRouteTo;
			} else {
				var user = _v0.a;
				if (maybeRoute.$ === 1) {
					var $temp$maybeRoute = $elm$core$Maybe$Just($author$project$Route$Home),
						$temp$model = A2($author$project$Main$setError, 'Stránka neexistuje.', updatedModel);
					maybeRoute = $temp$maybeRoute;
					model = $temp$model;
					continue changeRouteTo;
				} else {
					switch (maybeRoute.a.$) {
						case 2:
							var _v5 = maybeRoute.a;
							return _Utils_Tuple2(
								updatedModel,
								A2(
									$author$project$Api$logout,
									A2($elm$http$Http$expectJson, $author$project$Main$LogoutCompleted, $author$project$Main$logoutDecoder),
									user));
						case 1:
							var _v6 = maybeRoute.a;
							var $temp$maybeRoute = $elm$core$Maybe$Just($author$project$Route$Home),
								$temp$model = updatedModel;
							maybeRoute = $temp$maybeRoute;
							model = $temp$model;
							continue changeRouteTo;
						case 3:
							var _v7 = maybeRoute.a;
							var $temp$maybeRoute = $elm$core$Maybe$Just($author$project$Route$Home),
								$temp$model = updatedModel;
							maybeRoute = $temp$maybeRoute;
							model = $temp$model;
							continue changeRouteTo;
						case 4:
							var id = maybeRoute.a.a;
							return A3(
								transition,
								$author$project$Main$CaseLoaded,
								A2($author$project$Page$Case$load, user, id),
								user);
						case 0:
							var _v8 = maybeRoute.a;
							return A3(
								transition,
								$author$project$Main$HomeLoaded,
								$author$project$Page$Home$load(user),
								user);
						default:
							var _v9 = maybeRoute.a;
							return A2(
								load,
								$author$project$Main$NewCase($author$project$Page$NewCase$initModel),
								user);
					}
				}
			}
		}
	});
var $author$project$Main$Desktop = 1;
var $author$project$Main$Mobile = 0;
var $author$project$Main$deviceByWidth = function (width) {
	return (width > 767) ? 1 : 0;
};
var $author$project$Type$User$User = F5(
	function (name, token, tokenRefresh, expiratesIn, tokenType) {
		return {cf: expiratesIn, dI: name, cY: token, cZ: tokenRefresh, c_: tokenType};
	});
var $author$project$Type$User$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'token_type',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'expires_in',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'refresh_token',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'access_token',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'name',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Type$User$User))))));
var $author$project$Type$User$fromStoreDecoder = A2($elm$json$Json$Decode$field, 'user', $author$project$Type$User$decoder);
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {D: frag, G: params, C: unvisited, aU: value, K: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.C;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.aU);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.aU);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.cz),
					$elm$url$Url$Parser$prepareQuery(url.Q),
					url.ch,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$Case = function (a) {
	return {$: 4, a: a};
};
var $author$project$Route$ForgotPassword = {$: 3};
var $author$project$Route$Logout = {$: 2};
var $author$project$Route$NewCase = {$: 5};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.K;
			var unvisited = _v0.C;
			var params = _v0.G;
			var frag = _v0.D;
			var value = _v0.aU;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$int = A2($elm$url$Url$Parser$custom, 'NUMBER', $elm$core$String$toInt);
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.K;
		var unvisited = _v0.C;
		var params = _v0.G;
		var frag = _v0.D;
		var value = _v0.aU;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.K;
			var unvisited = _v1.C;
			var params = _v1.G;
			var frag = _v1.D;
			var value = _v1.aU;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.K;
		var unvisited = _v0.C;
		var params = _v0.G;
		var frag = _v0.D;
		var value = _v0.aU;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Route$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Route$Home, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Login,
			$elm$url$Url$Parser$s('login')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Logout,
			$elm$url$Url$Parser$s('logout')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$ForgotPassword,
			$elm$url$Url$Parser$s('forgot-password')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Case,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('case'),
				$elm$url$Url$Parser$int)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$NewCase,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('case'),
				$elm$url$Url$Parser$s('new')))
		]));
var $author$project$Route$fromUrl = function (url) {
	return A2(
		$elm$url$Url$Parser$parse,
		$author$project$Route$parser,
		_Utils_update(
			url,
			{cz: url.cz}));
};
var $author$project$Main$Blank = {$: 0};
var $author$project$Main$Model = function (session) {
	return function (navKey) {
		return function (maybeRoute) {
			return function (pageState) {
				return function (cases) {
					return function (casesLoaded) {
						return function (search) {
							return function (userMenuOpened) {
								return function (timeNow) {
									return function (dialog) {
										return function (errorDialog) {
											return function (device) {
												return {v: cases, a0: casesLoaded, M: device, au: dialog, aw: errorDialog, ac: maybeRoute, x: navKey, g: pageState, I: search, m: session, J: timeNow, ap: userMenuOpened};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Cases$initModel = _List_Nil;
var $author$project$Search$Model = F3(
	function (query, scrollTo, isMobileFormOpened) {
		return {aD: isMobileFormOpened, Q: query, d1: scrollTo};
	});
var $author$project$Search$initModel = A3($author$project$Search$Model, '', $elm$core$Maybe$Nothing, false);
var $author$project$Main$initModel = F5(
	function (session, navKey, route, timeNow, device) {
		return $author$project$Main$Model(session)(navKey)(route)(
			$author$project$Main$Loaded($author$project$Main$Blank))($author$project$Cases$initModel)(false)($author$project$Search$initModel)(false)(timeNow)($elm$core$Maybe$Nothing)($elm$core$Maybe$Nothing)(device);
	});
var $author$project$Main$init = F3(
	function (flags, url, navKey) {
		var session = function () {
			var _v0 = flags.bV;
			if (!_v0.$) {
				var encodedUser = _v0.a;
				var _v1 = A2($elm$json$Json$Decode$decodeString, $author$project$Type$User$fromStoreDecoder, encodedUser);
				if (!_v1.$) {
					var user = _v1.a;
					return $author$project$Main$Logged(user);
				} else {
					return $author$project$Main$NotLogged;
				}
			} else {
				return $author$project$Main$NotLogged;
			}
		}();
		var maybeRoute = $author$project$Route$fromUrl(url);
		var model = A5(
			$author$project$Main$initModel,
			session,
			navKey,
			maybeRoute,
			$elm$time$Time$millisToPosix(flags.J),
			$author$project$Main$deviceByWidth(flags.bY));
		return A2($author$project$Main$changeRouteTo, maybeRoute, model);
	});
var $author$project$Main$GotNewWindowWidth = function (a) {
	return {$: 20, a: a};
};
var $author$project$Main$OnStoreChange = function (a) {
	return {$: 8, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {cA: pids, cV: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {cd: event, cs: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.cA,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.cs;
		var event = _v0.cd;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.cV);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $author$project$Main$onStoreChange = _Platform_incomingPort('onStoreChange', $elm$json$Json$Decode$string);
var $author$project$Main$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Main$onStoreChange($author$project$Main$OnStoreChange),
				$elm$browser$Browser$Events$onResize(
				F2(
					function (width, _v1) {
						return $author$project$Main$GotNewWindowWidth(width);
					}))
			]));
};
var $author$project$Main$Case = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$CaseMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$ForgotPasswordMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$Home = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$HomeMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$LoginMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$NewCaseMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$SearchMsg = function (a) {
	return {$: 4, a: a};
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Case$Model = F4(
	function (form, caseDetail, timeNow, downloadingFiles) {
		return {dg: caseDetail, N: downloadingFiles, az: form, J: timeNow};
	});
var $author$project$Page$Case$Basic = 0;
var $author$project$Page$Case$Form = F5(
	function (description, file, error, size, sending) {
		return {V: description, cc: error, Y: file, ah: sending, ai: size};
	});
var $author$project$Page$Case$initForm = A5($author$project$Page$Case$Form, '', $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, 0, false);
var $author$project$Page$Case$initModel = F2(
	function (caseDetail, timeNow) {
		return A4($author$project$Page$Case$Model, $author$project$Page$Case$initForm, caseDetail, timeNow, _List_Nil);
	});
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$core$Maybe$destruct = F3(
	function (_default, func, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return func(a);
		} else {
			return _default;
		}
	});
var $author$project$Type$User$storeCache = _Platform_outgoingPort(
	'storeCache',
	function ($) {
		return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$core$Basics$identity, $);
	});
var $author$project$Type$User$logout = $elm$core$Platform$Cmd$batch(
	_List_fromArray(
		[
			$author$project$Type$User$storeCache($elm$core$Maybe$Nothing)
		]));
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Route$routeToPieces = function (page) {
	switch (page.$) {
		case 0:
			return _List_Nil;
		case 1:
			return _List_fromArray(
				['login']);
		case 2:
			return _List_fromArray(
				['logout']);
		case 3:
			return _List_fromArray(
				['forgot-password']);
		case 4:
			var id = page.a;
			return _List_fromArray(
				[
					'case',
					$elm$core$String$fromInt(id)
				]);
		default:
			return _List_fromArray(
				['case', 'new']);
	}
};
var $author$project$Route$routeToString = function (page) {
	return '/' + A2(
		$elm$core$String$join,
		'/',
		$author$project$Route$routeToPieces(page));
};
var $author$project$Route$newUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$pushUrl,
			key,
			$author$project$Route$routeToString(route));
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Main$Dialog = F2(
	function (title, message) {
		return {bi: message, ed: title};
	});
var $author$project$Main$openDialog = F3(
	function (maybeTitle, message, model) {
		return _Utils_update(
			model,
			{
				au: $elm$core$Maybe$Just(
					A2($author$project$Main$Dialog, maybeTitle, message))
			});
	});
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Route$replaceUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$replaceUrl,
			key,
			$author$project$Route$routeToString(route));
	});
var $author$project$Type$Case$historyItemId = function (id) {
	return 'history-' + $elm$core$String$fromInt(id);
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Page$Case$scrollToElement = _Platform_outgoingPort('scrollToElement', $elm$json$Json$Encode$string);
var $author$project$Page$Case$scrollTo = F2(
	function (maybeCaseDetail, maybeScrollElementId) {
		var _v0 = _Utils_Tuple2(maybeCaseDetail, maybeScrollElementId);
		if (!_v0.a.$) {
			if (_v0.b.$ === 1) {
				var caseDetail = _v0.a.a;
				var _v1 = _v0.b;
				var maybeLastHistory = $elm$core$List$head(
					$elm$core$List$reverse(caseDetail.dy));
				if (!maybeLastHistory.$) {
					var lastHistory = maybeLastHistory.a;
					return $author$project$Page$Case$scrollToElement(
						$author$project$Type$Case$historyItemId(lastHistory.co));
				} else {
					return $elm$core$Platform$Cmd$none;
				}
			} else {
				var scrollElementId = _v0.b.a;
				return $author$project$Page$Case$scrollToElement(scrollElementId);
			}
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Main$setDevice = F2(
	function (width, model) {
		return _Utils_update(
			model,
			{
				M: $author$project$Main$deviceByWidth(width)
			});
	});
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Cases$sortByLatest = function (cases) {
	return $elm$core$List$reverse(
		A2(
			$elm$core$List$sortBy,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.c0;
				},
				$elm$time$Time$posixToMillis),
			cases));
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Type$User$encode = function (user) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(user.dI)),
				_Utils_Tuple2(
				'access_token',
				$elm$json$Json$Encode$string(user.cY)),
				_Utils_Tuple2(
				'refresh_token',
				$elm$json$Json$Encode$string(user.cZ)),
				_Utils_Tuple2(
				'expires_in',
				$elm$json$Json$Encode$int(user.cf)),
				_Utils_Tuple2(
				'token_type',
				$elm$json$Json$Encode$string(user.c_))
			]));
};
var $author$project$Type$User$toStore = function (user) {
	var json = $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'user',
				$author$project$Type$User$encode(user))
			]));
	return $author$project$Type$User$storeCache(
		$elm$core$Maybe$Just(json));
};
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.cG;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.ch,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.Q,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.cB,
					_Utils_ap(http, url.cn)),
				url.cz)));
};
var $author$project$Cases$Failure = function (a) {
	return {$: 1, a: a};
};
var $author$project$Cases$Success = {$: 0};
var $author$project$Cases$update = F2(
	function (msg, model) {
		if (msg.$ === 1) {
			return _Utils_Tuple3(
				model,
				$elm$core$Platform$Cmd$none,
				$author$project$Cases$Failure('Seznam případů se nepodařilo načíst.'));
		} else {
			var cases = msg.a;
			return _Utils_Tuple3(
				$author$project$Cases$sortByLatest(cases),
				$elm$core$Platform$Cmd$none,
				$author$project$Cases$Success);
		}
	});
var $author$project$Page$Case$Created = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Page$Case$Failure = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Case$File = F2(
	function (content, name) {
		return {b9: content, dI: name};
	});
var $author$project$Page$Case$FileRead = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Page$Case$FileSelected = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Case$NoOp = {$: 0};
var $author$project$Page$Case$AddCaseHistoryCompleted = function (a) {
	return {$: 9, a: a};
};
var $author$project$Api$addCaseHistory = F3(
	function (body, expect, user) {
		return A5(
			$author$project$Api$request,
			'POST',
			A2($author$project$Api$url, 'addCaseHistory', _List_Nil),
			body,
			expect,
			$elm$core$Maybe$Just(user));
	});
var $author$project$Page$Case$addCaseHistoryResponseDecoder = A2($elm$json$Json$Decode$field, 'incident_history_id', $elm$json$Json$Decode$int);
var $author$project$Page$Case$addCaseHistory = F5(
	function (user, caseId, message, fileContent, fileName) {
		return A3(
			$author$project$Api$addCaseHistory,
			$elm$http$Http$multipartBody(
				_List_fromArray(
					[
						A2(
						$elm$http$Http$stringPart,
						'incident_id',
						$elm$core$String$fromInt(caseId)),
						A2($elm$http$Http$stringPart, 'message', message),
						A2($elm$http$Http$stringPart, 'attachement_name', fileName),
						A2($elm$http$Http$stringPart, 'attachement_file', fileContent)
					])),
			A2($elm$http$Http$expectJson, $author$project$Page$Case$AddCaseHistoryCompleted, $author$project$Page$Case$addCaseHistoryResponseDecoder),
			user);
	});
var $author$project$Page$Case$addFileToDownloading = F2(
	function (file, model) {
		return _Utils_update(
			model,
			{
				N: A2($elm$core$List$cons, file, model.N)
			});
	});
var $elm$file$File$Download$bytes = F3(
	function (name, mime, content) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$never,
			A3(
				_File_download,
				name,
				mime,
				_File_makeBytesSafeForInternetExplorer(content)));
	});
var $author$project$Page$Case$FileDownloadComplete = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Page$Case$bytesResolve = function (response) {
	switch (response.$) {
		case 0:
			var url = response.a;
			return $elm$core$Result$Err(
				$elm$http$Http$BadUrl(url));
		case 1:
			return $elm$core$Result$Err($elm$http$Http$Timeout);
		case 2:
			return $elm$core$Result$Err($elm$http$Http$NetworkError);
		case 3:
			var metadata = response.a;
			return $elm$core$Result$Err(
				$elm$http$Http$BadStatus(metadata.d5));
		default:
			var body = response.b;
			return $elm$core$Result$Ok(body);
	}
};
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{b5: $elm$http$Http$emptyBody, dr: r.dr, ck: _List_Nil, cw: 'GET', cX: $elm$core$Maybe$Nothing, ee: $elm$core$Maybe$Nothing, c1: r.c1});
};
var $author$project$Page$Case$downloadFile = function (file) {
	return $elm$http$Http$get(
		{
			dr: A2(
				$elm$http$Http$expectBytesResponse,
				$author$project$Page$Case$FileDownloadComplete(file),
				$author$project$Page$Case$bytesResolve),
			c1: file.c1
		});
};
var $elm$file$File$Select$file = F2(
	function (mimes, toMsg) {
		return A2(
			$elm$core$Task$perform,
			toMsg,
			_File_uploadOne(mimes));
	});
var $elm$bytes$Bytes$Encode$getWidth = function (builder) {
	switch (builder.$) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 4;
		case 6:
			return 4;
		case 7:
			return 8;
		case 8:
			var w = builder.a;
			return w;
		case 9:
			var w = builder.a;
			return w;
		default:
			var bs = builder.a;
			return _Bytes_width(bs);
	}
};
var $elm$bytes$Bytes$LE = 0;
var $elm$bytes$Bytes$Encode$write = F3(
	function (builder, mb, offset) {
		switch (builder.$) {
			case 0:
				var n = builder.a;
				return A3(_Bytes_write_i8, mb, offset, n);
			case 1:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i16, mb, offset, n, !e);
			case 2:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i32, mb, offset, n, !e);
			case 3:
				var n = builder.a;
				return A3(_Bytes_write_u8, mb, offset, n);
			case 4:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u16, mb, offset, n, !e);
			case 5:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u32, mb, offset, n, !e);
			case 6:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f32, mb, offset, n, !e);
			case 7:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f64, mb, offset, n, !e);
			case 8:
				var bs = builder.b;
				return A3($elm$bytes$Bytes$Encode$writeSequence, bs, mb, offset);
			case 9:
				var s = builder.b;
				return A3(_Bytes_write_string, mb, offset, s);
			default:
				var bs = builder.a;
				return A3(_Bytes_write_bytes, mb, offset, bs);
		}
	});
var $elm$bytes$Bytes$Encode$writeSequence = F3(
	function (builders, mb, offset) {
		writeSequence:
		while (true) {
			if (!builders.b) {
				return offset;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$builders = bs,
					$temp$mb = mb,
					$temp$offset = A3($elm$bytes$Bytes$Encode$write, b, mb, offset);
				builders = $temp$builders;
				mb = $temp$mb;
				offset = $temp$offset;
				continue writeSequence;
			}
		}
	});
var $elm$bytes$Bytes$Decode$decode = F2(
	function (_v0, bs) {
		var decoder = _v0;
		return A2(_Bytes_decode, decoder, bs);
	});
var $elm$bytes$Bytes$Decode$Decoder = $elm$core$Basics$identity;
var $elm$bytes$Bytes$Decode$loopHelp = F4(
	function (state, callback, bites, offset) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var decoder = _v0;
			var _v1 = A2(decoder, bites, offset);
			var newOffset = _v1.a;
			var step = _v1.b;
			if (!step.$) {
				var newState = step.a;
				var $temp$state = newState,
					$temp$callback = callback,
					$temp$bites = bites,
					$temp$offset = newOffset;
				state = $temp$state;
				callback = $temp$callback;
				bites = $temp$bites;
				offset = $temp$offset;
				continue loopHelp;
			} else {
				var result = step.a;
				return _Utils_Tuple2(newOffset, result);
			}
		}
	});
var $elm$bytes$Bytes$Decode$loop = F2(
	function (state, callback) {
		return A2($elm$bytes$Bytes$Decode$loopHelp, state, callback);
	});
var $elm$bytes$Bytes$Decode$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$bytes$Bytes$Decode$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $danfishgold$base64_bytes$Decode$lowest6BitsMask = 63;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Char$fromCode = _Char_fromCode;
var $danfishgold$base64_bytes$Decode$unsafeToChar = function (n) {
	if (n <= 25) {
		return $elm$core$Char$fromCode(65 + n);
	} else {
		if (n <= 51) {
			return $elm$core$Char$fromCode(97 + (n - 26));
		} else {
			if (n <= 61) {
				return $elm$core$Char$fromCode(48 + (n - 52));
			} else {
				switch (n) {
					case 62:
						return '+';
					case 63:
						return '/';
					default:
						return '\u0000';
				}
			}
		}
	}
};
var $danfishgold$base64_bytes$Decode$bitsToChars = F2(
	function (bits, missing) {
		var s = $danfishgold$base64_bytes$Decode$unsafeToChar(bits & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var r = $danfishgold$base64_bytes$Decode$unsafeToChar((bits >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var q = $danfishgold$base64_bytes$Decode$unsafeToChar((bits >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var p = $danfishgold$base64_bytes$Decode$unsafeToChar(bits >>> 18);
		switch (missing) {
			case 0:
				return A2(
					$elm$core$String$cons,
					p,
					A2(
						$elm$core$String$cons,
						q,
						A2(
							$elm$core$String$cons,
							r,
							$elm$core$String$fromChar(s))));
			case 1:
				return A2(
					$elm$core$String$cons,
					p,
					A2(
						$elm$core$String$cons,
						q,
						A2($elm$core$String$cons, r, '=')));
			case 2:
				return A2(
					$elm$core$String$cons,
					p,
					A2($elm$core$String$cons, q, '=='));
			default:
				return '';
		}
	});
var $danfishgold$base64_bytes$Decode$bitsToCharSpecialized = F4(
	function (bits1, bits2, bits3, accum) {
		var z = $danfishgold$base64_bytes$Decode$unsafeToChar((bits3 >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var y = $danfishgold$base64_bytes$Decode$unsafeToChar((bits3 >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var x = $danfishgold$base64_bytes$Decode$unsafeToChar(bits3 >>> 18);
		var w = $danfishgold$base64_bytes$Decode$unsafeToChar(bits3 & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var s = $danfishgold$base64_bytes$Decode$unsafeToChar(bits1 & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var r = $danfishgold$base64_bytes$Decode$unsafeToChar((bits1 >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var q = $danfishgold$base64_bytes$Decode$unsafeToChar((bits1 >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var p = $danfishgold$base64_bytes$Decode$unsafeToChar(bits1 >>> 18);
		var d = $danfishgold$base64_bytes$Decode$unsafeToChar(bits2 & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var c = $danfishgold$base64_bytes$Decode$unsafeToChar((bits2 >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var b = $danfishgold$base64_bytes$Decode$unsafeToChar((bits2 >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var a = $danfishgold$base64_bytes$Decode$unsafeToChar(bits2 >>> 18);
		return A2(
			$elm$core$String$cons,
			x,
			A2(
				$elm$core$String$cons,
				y,
				A2(
					$elm$core$String$cons,
					z,
					A2(
						$elm$core$String$cons,
						w,
						A2(
							$elm$core$String$cons,
							a,
							A2(
								$elm$core$String$cons,
								b,
								A2(
									$elm$core$String$cons,
									c,
									A2(
										$elm$core$String$cons,
										d,
										A2(
											$elm$core$String$cons,
											p,
											A2(
												$elm$core$String$cons,
												q,
												A2(
													$elm$core$String$cons,
													r,
													A2($elm$core$String$cons, s, accum))))))))))));
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $danfishgold$base64_bytes$Decode$decode18Help = F5(
	function (a, b, c, d, e) {
		var combined6 = ((255 & d) << 16) | e;
		var combined5 = d >>> 8;
		var combined4 = 16777215 & c;
		var combined3 = ((65535 & b) << 8) | (c >>> 24);
		var combined2 = ((255 & a) << 16) | (b >>> 16);
		var combined1 = a >>> 8;
		return A4(
			$danfishgold$base64_bytes$Decode$bitsToCharSpecialized,
			combined3,
			combined2,
			combined1,
			A4($danfishgold$base64_bytes$Decode$bitsToCharSpecialized, combined6, combined5, combined4, ''));
	});
var $elm$bytes$Bytes$Decode$map5 = F6(
	function (func, _v0, _v1, _v2, _v3, _v4) {
		var decodeA = _v0;
		var decodeB = _v1;
		var decodeC = _v2;
		var decodeD = _v3;
		var decodeE = _v4;
		return F2(
			function (bites, offset) {
				var _v5 = A2(decodeA, bites, offset);
				var aOffset = _v5.a;
				var a = _v5.b;
				var _v6 = A2(decodeB, bites, aOffset);
				var bOffset = _v6.a;
				var b = _v6.b;
				var _v7 = A2(decodeC, bites, bOffset);
				var cOffset = _v7.a;
				var c = _v7.b;
				var _v8 = A2(decodeD, bites, cOffset);
				var dOffset = _v8.a;
				var d = _v8.b;
				var _v9 = A2(decodeE, bites, dOffset);
				var eOffset = _v9.a;
				var e = _v9.b;
				return _Utils_Tuple2(
					eOffset,
					A5(func, a, b, c, d, e));
			});
	});
var $elm$bytes$Bytes$BE = 1;
var $elm$bytes$Bytes$Decode$unsignedInt16 = function (endianness) {
	return _Bytes_read_u16(!endianness);
};
var $danfishgold$base64_bytes$Decode$u16BE = $elm$bytes$Bytes$Decode$unsignedInt16(1);
var $elm$bytes$Bytes$Decode$unsignedInt32 = function (endianness) {
	return _Bytes_read_u32(!endianness);
};
var $danfishgold$base64_bytes$Decode$u32BE = $elm$bytes$Bytes$Decode$unsignedInt32(1);
var $danfishgold$base64_bytes$Decode$decode18Bytes = A6($elm$bytes$Bytes$Decode$map5, $danfishgold$base64_bytes$Decode$decode18Help, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u16BE);
var $elm$bytes$Bytes$Decode$map = F2(
	function (func, _v0) {
		var decodeA = _v0;
		return F2(
			function (bites, offset) {
				var _v1 = A2(decodeA, bites, offset);
				var aOffset = _v1.a;
				var a = _v1.b;
				return _Utils_Tuple2(
					aOffset,
					func(a));
			});
	});
var $elm$bytes$Bytes$Decode$map2 = F3(
	function (func, _v0, _v1) {
		var decodeA = _v0;
		var decodeB = _v1;
		return F2(
			function (bites, offset) {
				var _v2 = A2(decodeA, bites, offset);
				var aOffset = _v2.a;
				var a = _v2.b;
				var _v3 = A2(decodeB, bites, aOffset);
				var bOffset = _v3.a;
				var b = _v3.b;
				return _Utils_Tuple2(
					bOffset,
					A2(func, a, b));
			});
	});
var $elm$bytes$Bytes$Decode$map3 = F4(
	function (func, _v0, _v1, _v2) {
		var decodeA = _v0;
		var decodeB = _v1;
		var decodeC = _v2;
		return F2(
			function (bites, offset) {
				var _v3 = A2(decodeA, bites, offset);
				var aOffset = _v3.a;
				var a = _v3.b;
				var _v4 = A2(decodeB, bites, aOffset);
				var bOffset = _v4.a;
				var b = _v4.b;
				var _v5 = A2(decodeC, bites, bOffset);
				var cOffset = _v5.a;
				var c = _v5.b;
				return _Utils_Tuple2(
					cOffset,
					A3(func, a, b, c));
			});
	});
var $elm$bytes$Bytes$Decode$succeed = function (a) {
	return F2(
		function (_v0, offset) {
			return _Utils_Tuple2(offset, a);
		});
};
var $elm$bytes$Bytes$Decode$unsignedInt8 = _Bytes_read_u8;
var $danfishgold$base64_bytes$Decode$loopHelp = function (_v0) {
	var remaining = _v0.aP;
	var string = _v0.aS;
	if (remaining >= 18) {
		return A2(
			$elm$bytes$Bytes$Decode$map,
			function (result) {
				return $elm$bytes$Bytes$Decode$Loop(
					{
						aP: remaining - 18,
						aS: _Utils_ap(string, result)
					});
			},
			$danfishgold$base64_bytes$Decode$decode18Bytes);
	} else {
		if (remaining >= 3) {
			var helper = F3(
				function (a, b, c) {
					var combined = ((a << 16) | (b << 8)) | c;
					return $elm$bytes$Bytes$Decode$Loop(
						{
							aP: remaining - 3,
							aS: _Utils_ap(
								string,
								A2($danfishgold$base64_bytes$Decode$bitsToChars, combined, 0))
						});
				});
			return A4($elm$bytes$Bytes$Decode$map3, helper, $elm$bytes$Bytes$Decode$unsignedInt8, $elm$bytes$Bytes$Decode$unsignedInt8, $elm$bytes$Bytes$Decode$unsignedInt8);
		} else {
			if (!remaining) {
				return $elm$bytes$Bytes$Decode$succeed(
					$elm$bytes$Bytes$Decode$Done(string));
			} else {
				if (remaining === 2) {
					var helper = F2(
						function (a, b) {
							var combined = (a << 16) | (b << 8);
							return $elm$bytes$Bytes$Decode$Done(
								_Utils_ap(
									string,
									A2($danfishgold$base64_bytes$Decode$bitsToChars, combined, 1)));
						});
					return A3($elm$bytes$Bytes$Decode$map2, helper, $elm$bytes$Bytes$Decode$unsignedInt8, $elm$bytes$Bytes$Decode$unsignedInt8);
				} else {
					return A2(
						$elm$bytes$Bytes$Decode$map,
						function (a) {
							return $elm$bytes$Bytes$Decode$Done(
								_Utils_ap(
									string,
									A2($danfishgold$base64_bytes$Decode$bitsToChars, a << 16, 2)));
						},
						$elm$bytes$Bytes$Decode$unsignedInt8);
				}
			}
		}
	}
};
var $danfishgold$base64_bytes$Decode$decoder = function (width) {
	return A2(
		$elm$bytes$Bytes$Decode$loop,
		{aP: width, aS: ''},
		$danfishgold$base64_bytes$Decode$loopHelp);
};
var $elm$bytes$Bytes$width = _Bytes_width;
var $danfishgold$base64_bytes$Decode$fromBytes = function (bytes) {
	return A2(
		$elm$bytes$Bytes$Decode$decode,
		$danfishgold$base64_bytes$Decode$decoder(
			$elm$bytes$Bytes$width(bytes)),
		bytes);
};
var $danfishgold$base64_bytes$Base64$fromBytes = $danfishgold$base64_bytes$Decode$fromBytes;
var $elm$file$File$name = _File_name;
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $author$project$Page$Case$removeFileFromDownloading = F2(
	function (file, model) {
		return _Utils_update(
			model,
			{
				N: A2(
					$elm$core$List$filter,
					function (item) {
						return !_Utils_eq(item, file);
					},
					model.N)
			});
	});
var $elm$file$File$toBytes = _File_toBytes;
var $author$project$Page$Case$updateForm = F2(
	function (form, model) {
		return _Utils_update(
			model,
			{az: form});
	});
var $author$project$Page$Case$update = F3(
	function (user, msg, model) {
		var form = model.az;
		var caseDetail = model.dg;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Page$Case$NoOp);
			case 1:
				var description = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Case$updateForm,
						_Utils_update(
							form,
							{V: description}),
						model),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Case$NoOp);
			case 2:
				return _Utils_Tuple3(
					model,
					A2($elm$file$File$Select$file, _List_Nil, $author$project$Page$Case$FileSelected),
					$author$project$Page$Case$NoOp);
			case 3:
				var file = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$elm$core$Task$perform,
						$author$project$Page$Case$FileRead(
							$elm$file$File$name(file)),
						$elm$file$File$toBytes(file)),
					$author$project$Page$Case$NoOp);
			case 4:
				var name = msg.a;
				var bytes = msg.b;
				var maybeContent = $danfishgold$base64_bytes$Base64$fromBytes(bytes);
				if (!maybeContent.$) {
					var content = maybeContent.a;
					return _Utils_Tuple3(
						A2(
							$author$project$Page$Case$updateForm,
							_Utils_update(
								form,
								{
									Y: $elm$core$Maybe$Just(
										A2($author$project$Page$Case$File, content, name))
								}),
							model),
						$elm$core$Platform$Cmd$none,
						$author$project$Page$Case$NoOp);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Page$Case$NoOp);
				}
			case 5:
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Case$updateForm,
						_Utils_update(
							form,
							{Y: $elm$core$Maybe$Nothing}),
						model),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Case$NoOp);
			case 6:
				var file = msg.a;
				return _Utils_Tuple3(
					A2($author$project$Page$Case$addFileToDownloading, file, model),
					$author$project$Page$Case$downloadFile(file),
					$author$project$Page$Case$NoOp);
			case 7:
				if (!msg.b.$) {
					var file = msg.a;
					var bytes = msg.b.a;
					return _Utils_Tuple3(
						A2($author$project$Page$Case$removeFileFromDownloading, file, model),
						A3($elm$file$File$Download$bytes, file.dI, '', bytes),
						$author$project$Page$Case$NoOp);
				} else {
					var file = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Page$Case$removeFileFromDownloading, file, model),
						$elm$core$Platform$Cmd$none,
						$author$project$Page$Case$Failure('Soubor se nepodařilo stáhnout.'));
				}
			case 8:
				var description = $elm$core$String$trim(form.V);
				var _v2 = function () {
					var _v3 = form.Y;
					if (!_v3.$) {
						var file = _v3.a;
						return _Utils_Tuple2(file.b9, file.dI);
					} else {
						return _Utils_Tuple2('', '');
					}
				}();
				var fileContent = _v2.a;
				var fileName = _v2.b;
				var _v4 = $elm$core$String$isEmpty(description) ? _Utils_Tuple3(
					$elm$core$Maybe$Just('Nezadali jste doplňující zprávu.'),
					$elm$core$Platform$Cmd$none,
					false) : _Utils_Tuple3(
					$elm$core$Maybe$Nothing,
					A5($author$project$Page$Case$addCaseHistory, user, caseDetail.co, form.V, fileContent, fileName),
					true);
				var error = _v4.a;
				var cmd = _v4.b;
				var sending = _v4.c;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Case$updateForm,
						_Utils_update(
							form,
							{cc: error, ah: sending}),
						model),
					cmd,
					$author$project$Page$Case$NoOp);
			case 9:
				if (msg.a.$ === 1) {
					return _Utils_Tuple3(
						A2(
							$author$project$Page$Case$updateForm,
							_Utils_update(
								form,
								{ah: false}),
							model),
						$elm$core$Platform$Cmd$none,
						$author$project$Page$Case$Failure('Zprávu se nepodařilo odeslat.'));
				} else {
					var caseHistoryId = msg.a.a;
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						A2($author$project$Page$Case$Created, caseDetail.co, caseHistoryId));
				}
			default:
				var size = msg.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Page$Case$updateForm,
						_Utils_update(
							form,
							{ai: size}),
						model),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Case$NoOp);
		}
	});
var $author$project$Page$ForgotPassword$Failure = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$ForgotPassword$NoOp = {$: 0};
var $author$project$Page$ForgotPassword$Reseted = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Page$ForgotPassword$SubmitFormCompleted = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$ForgotPassword$Error = 1;
var $author$project$Page$ForgotPassword$Success = 0;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$resolve = $elm$json$Json$Decode$andThen($elm$core$Basics$identity);
var $author$project$Page$ForgotPassword$decoder = function () {
	var toDecoder = function (code) {
		if (code === 200) {
			return $elm$json$Json$Decode$succeed(0);
		} else {
			return $elm$json$Json$Decode$succeed(1);
		}
	};
	return $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$resolve(
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'code',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed(toDecoder)));
}();
var $author$project$Api$resetPassword = F2(
	function (body, expect) {
		return A5(
			$author$project$Api$request,
			'POST',
			A2($author$project$Api$url, 'resetPassword', _List_Nil),
			body,
			expect,
			$elm$core$Maybe$Nothing);
	});
var $author$project$Page$ForgotPassword$resetPassword = function (email) {
	return A2(
		$author$project$Api$resetPassword,
		$elm$http$Http$multipartBody(
			_List_fromArray(
				[
					A2($elm$http$Http$stringPart, 'email', email)
				])),
		A2($elm$http$Http$expectJson, $author$project$Page$ForgotPassword$SubmitFormCompleted, $author$project$Page$ForgotPassword$decoder));
};
var $author$project$Page$ForgotPassword$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var email = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{X: email}),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$ForgotPassword$NoOp);
			case 1:
				var _v1 = $elm$core$String$isEmpty(model.X) ? _Utils_Tuple3('Nezadali jste e-mail.', $elm$core$Platform$Cmd$none, false) : _Utils_Tuple3(
					'',
					$author$project$Page$ForgotPassword$resetPassword(model.X),
					true);
				var error = _v1.a;
				var cmd = _v1.b;
				var sending = _v1.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cc: error, ah: sending}),
					cmd,
					$author$project$Page$ForgotPassword$NoOp);
			default:
				if (msg.a.$ === 1) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{ah: false}),
						$elm$core$Platform$Cmd$none,
						$author$project$Page$ForgotPassword$Failure('Reset hesla se nezdařil. Nelze se připojit k serveru.'));
				} else {
					var response = msg.a.a;
					var newModel = _Utils_update(
						model,
						{ah: false});
					if (!response) {
						return _Utils_Tuple3(
							newModel,
							$elm$core$Platform$Cmd$none,
							A2($author$project$Page$ForgotPassword$Reseted, 'Reset hesla', 'Na Váš e-mail jsem právě odeslali informace s dalším postupem.'));
					} else {
						return _Utils_Tuple3(
							newModel,
							$elm$core$Platform$Cmd$none,
							$author$project$Page$ForgotPassword$Failure('Zadaný e-mail neexistuje.'));
					}
				}
		}
	});
var $author$project$Page$Home$update = F2(
	function (msg, model) {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Login$Failure = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Login$NoOp = {$: 0};
var $author$project$Page$Login$SetUser = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Login$SubmitFormCompleted = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Login$Authorized = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Login$Unauthorized = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Login$Error = function (code) {
	return {dk: code};
};
var $author$project$Page$Login$errorDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'code',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Page$Login$Error));
var $author$project$Page$Login$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$map, $author$project$Page$Login$Authorized, $author$project$Type$User$decoder),
			A2($elm$json$Json$Decode$map, $author$project$Page$Login$Unauthorized, $author$project$Page$Login$errorDecoder)
		]));
var $author$project$Api$login = F2(
	function (body, expect) {
		return A5(
			$author$project$Api$request,
			'POST',
			A2($author$project$Api$url, 'login', _List_Nil),
			body,
			expect,
			$elm$core$Maybe$Nothing);
	});
var $author$project$Page$Login$login = F2(
	function (email, password) {
		return A2(
			$author$project$Api$login,
			$elm$http$Http$multipartBody(
				_List_fromArray(
					[
						A2($elm$http$Http$stringPart, 'email', email),
						A2($elm$http$Http$stringPart, 'password', password)
					])),
			A2($elm$http$Http$expectJson, $author$project$Page$Login$SubmitFormCompleted, $author$project$Page$Login$decoder));
	});
var $author$project$Page$Login$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var email = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{X: email}),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Login$NoOp);
			case 1:
				var password = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ag: password}),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$Login$NoOp);
			case 2:
				var errorPassword = $elm$core$String$isEmpty(model.ag) ? 'Nezadali jste heslo.' : '';
				var errorEmail = $elm$core$String$isEmpty(model.X) ? 'Nezadali jste e-mail.' : '';
				var _v1 = $elm$core$String$isEmpty(
					_Utils_ap(errorEmail, errorPassword)) ? _Utils_Tuple2(
					A2($author$project$Page$Login$login, model.X, model.ag),
					true) : _Utils_Tuple2($elm$core$Platform$Cmd$none, false);
				var cmd = _v1.a;
				var sending = _v1.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ax: errorEmail, ay: errorPassword, ah: sending}),
					cmd,
					$author$project$Page$Login$NoOp);
			default:
				if (msg.a.$ === 1) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{ah: false}),
						$elm$core$Platform$Cmd$none,
						$author$project$Page$Login$Failure('Přihlášení se nezdařilo. Nelze se připojit k serveru.'));
				} else {
					var loginResponse = msg.a.a;
					var newModel = _Utils_update(
						model,
						{ah: false});
					if (!loginResponse.$) {
						var user = loginResponse.a;
						return _Utils_Tuple3(
							newModel,
							$elm$core$Platform$Cmd$none,
							$author$project$Page$Login$SetUser(user));
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								newModel,
								{cc: 'Přihlášení se nezdařilo. E-mail nebo heslo není správně.'}),
							$elm$core$Platform$Cmd$none,
							$author$project$Page$Login$NoOp);
					}
				}
		}
	});
var $author$project$Page$NewCase$Created = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$NewCase$Failure = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$NewCase$File = F2(
	function (content, name) {
		return {b9: content, dI: name};
	});
var $author$project$Page$NewCase$FileRead = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Page$NewCase$FileSelected = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$NewCase$NoOp = {$: 0};
var $author$project$Page$NewCase$AddCaseCompleted = function (a) {
	return {$: 7, a: a};
};
var $author$project$Api$addCase = F3(
	function (body, expect, user) {
		return A5(
			$author$project$Api$request,
			'POST',
			A2($author$project$Api$url, 'addCase', _List_Nil),
			body,
			expect,
			$elm$core$Maybe$Just(user));
	});
var $author$project$Page$NewCase$addCaseResponseDecoder = A2($elm$json$Json$Decode$field, 'incident_id', $elm$json$Json$Decode$int);
var $author$project$Page$NewCase$addCase = F2(
	function (user, subject) {
		return A3(
			$author$project$Api$addCase,
			$elm$http$Http$multipartBody(
				_List_fromArray(
					[
						A2($elm$http$Http$stringPart, 'incident_no', subject),
						A2($elm$http$Http$stringPart, 'event_description', subject)
					])),
			A2($elm$http$Http$expectJson, $author$project$Page$NewCase$AddCaseCompleted, $author$project$Page$NewCase$addCaseResponseDecoder),
			user);
	});
var $author$project$Page$NewCase$AddCaseHistoryCompleted = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$NewCase$addCaseHistoryResponseDecoder = A2($elm$json$Json$Decode$field, 'incident_history_id', $elm$json$Json$Decode$int);
var $author$project$Page$NewCase$addCaseHistory = F5(
	function (user, caseId, message, fileContent, fileName) {
		return A3(
			$author$project$Api$addCaseHistory,
			$elm$http$Http$multipartBody(
				_List_fromArray(
					[
						A2(
						$elm$http$Http$stringPart,
						'incident_id',
						$elm$core$String$fromInt(caseId)),
						A2($elm$http$Http$stringPart, 'message', message),
						A2($elm$http$Http$stringPart, 'attachement_name', fileName),
						A2($elm$http$Http$stringPart, 'attachement_file', fileContent)
					])),
			A2($elm$http$Http$expectJson, $author$project$Page$NewCase$AddCaseHistoryCompleted, $author$project$Page$NewCase$addCaseHistoryResponseDecoder),
			user);
	});
var $author$project$Page$NewCase$update = F3(
	function (user, msg, model) {
		switch (msg.$) {
			case 0:
				var subject = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ak: subject}),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$NewCase$NoOp);
			case 1:
				var description = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{V: description}),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$NewCase$NoOp);
			case 2:
				return _Utils_Tuple3(
					model,
					A2($elm$file$File$Select$file, _List_Nil, $author$project$Page$NewCase$FileSelected),
					$author$project$Page$NewCase$NoOp);
			case 3:
				var file = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$elm$core$Task$perform,
						$author$project$Page$NewCase$FileRead(
							$elm$file$File$name(file)),
						$elm$file$File$toBytes(file)),
					$author$project$Page$NewCase$NoOp);
			case 4:
				var name = msg.a;
				var bytes = msg.b;
				var maybeContent = $danfishgold$base64_bytes$Base64$fromBytes(bytes);
				if (!maybeContent.$) {
					var content = maybeContent.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								Y: $elm$core$Maybe$Just(
									A2($author$project$Page$NewCase$File, content, name))
							}),
						$elm$core$Platform$Cmd$none,
						$author$project$Page$NewCase$NoOp);
				} else {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Page$NewCase$NoOp);
				}
			case 5:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{Y: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none,
					$author$project$Page$NewCase$NoOp);
			case 6:
				var subject = $elm$core$String$trim(model.ak);
				var description = $elm$core$String$trim(model.V);
				var _v2 = $elm$core$String$isEmpty(subject) ? _Utils_Tuple3(
					$elm$core$Maybe$Just('Nezadali jste předmět požadavku.'),
					$elm$core$Platform$Cmd$none,
					false) : ($elm$core$String$isEmpty(description) ? _Utils_Tuple3(
					$elm$core$Maybe$Just('Nezadali jste popis požadavku.'),
					$elm$core$Platform$Cmd$none,
					false) : _Utils_Tuple3(
					$elm$core$Maybe$Nothing,
					A2($author$project$Page$NewCase$addCase, user, subject),
					true));
				var error = _v2.a;
				var cmd = _v2.b;
				var sending = _v2.c;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cc: error, ah: sending}),
					cmd,
					$author$project$Page$NewCase$NoOp);
			case 7:
				if (msg.a.$ === 1) {
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						$author$project$Page$NewCase$Failure('Případ se nepodařilo uložit na server.'));
				} else {
					var caseId = msg.a.a;
					var _v3 = function () {
						var _v4 = model.Y;
						if (!_v4.$) {
							var file = _v4.a;
							return _Utils_Tuple2(file.b9, file.dI);
						} else {
							return _Utils_Tuple2('', '');
						}
					}();
					var fileContent = _v3.a;
					var fileName = _v3.b;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								a$: $elm$core$Maybe$Just(caseId)
							}),
						A5($author$project$Page$NewCase$addCaseHistory, user, caseId, model.V, fileContent, fileName),
						$author$project$Page$NewCase$NoOp);
				}
			default:
				if (msg.a.$ === 1) {
					return _Utils_Tuple3(
						model,
						$elm$core$Platform$Cmd$none,
						$author$project$Page$NewCase$Failure('Detail případu se nepodařilo uložit na server.'));
				} else {
					var _v5 = model.a$;
					if (!_v5.$) {
						var caseId = _v5.a;
						return _Utils_Tuple3(
							model,
							$elm$core$Platform$Cmd$none,
							$author$project$Page$NewCase$Created(caseId));
					} else {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Page$NewCase$NoOp);
					}
				}
		}
	});
var $author$project$Search$GoToCase = function (a) {
	return {$: 1, a: a};
};
var $author$project$Search$NoOp = {$: 0};
var $author$project$Search$SetQuery = function (a) {
	return {$: 0, a: a};
};
var $author$project$Search$clearSearch = function (model) {
	return _Utils_update(
		model,
		{aD: false, Q: ''});
};
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $author$project$Type$Case$historyItemFilesId = function (id) {
	return 'history-' + ($elm$core$String$fromInt(id) + '-files');
};
var $author$project$Search$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var query = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{Q: query}),
					$elm$core$Platform$Cmd$none,
					$author$project$Search$NoOp);
			case 1:
				var search = msg.a;
				var scrollTo = function () {
					var _v1 = search.bU;
					switch (_v1) {
						case 0:
							return $elm$core$Maybe$Nothing;
						case 1:
							return $elm$core$Maybe$Just(
								$author$project$Type$Case$historyItemId(
									A2($elm$core$Maybe$withDefault, 0, search.P)));
						default:
							return $elm$core$Maybe$Just(
								$author$project$Type$Case$historyItemFilesId(
									A2($elm$core$Maybe$withDefault, 0, search.P)));
					}
				}();
				var newModel = $author$project$Search$clearSearch(model);
				return _Utils_Tuple3(
					_Utils_update(
						newModel,
						{d1: scrollTo}),
					$elm$core$Platform$Cmd$none,
					$author$project$Search$GoToCase(search.a$));
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aD: true}),
					A2(
						$elm$core$Task$attempt,
						function (_v2) {
							return $author$project$Search$SetQuery('');
						},
						$elm$browser$Browser$Dom$focus('search-input-mobile')),
					$author$project$Search$NoOp);
			default:
				return _Utils_Tuple3(
					$author$project$Search$clearSearch(model),
					$elm$core$Platform$Cmd$none,
					$author$project$Search$NoOp);
		}
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		var page = $author$project$Main$getPage(model.g);
		var _v0 = _Utils_Tuple2(msg, page);
		_v0$24:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					var _v1 = _v0.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 1:
					if (_v0.b.$ === 4) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						var _v2 = A2($author$project$Page$Login$update, subMsg, subModel);
						var loginModel = _v2.a;
						var cmd = _v2.b;
						var externalMsg = _v2.c;
						var newCmd = A2($elm$core$Platform$Cmd$map, $author$project$Main$LoginMsg, cmd);
						var newModel = _Utils_update(
							model,
							{
								g: $author$project$Main$Loaded(
									$author$project$Main$Login(loginModel)),
								m: $author$project$Main$NotLogged
							});
						switch (externalMsg.$) {
							case 0:
								return _Utils_Tuple2(newModel, newCmd);
							case 1:
								var user = externalMsg.a;
								return _Utils_Tuple2(
									_Utils_update(
										newModel,
										{
											m: $author$project$Main$Logged(user)
										}),
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												newCmd,
												$author$project$Type$User$toStore(user),
												A2($author$project$Route$replaceUrl, model.x, $author$project$Route$Home)
											])));
							default:
								var error = externalMsg.a;
								return _Utils_Tuple2(
									A2($author$project$Main$setError, error, newModel),
									newCmd);
						}
					} else {
						break _v0$24;
					}
				case 2:
					if (_v0.b.$ === 5) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						var _v4 = A2($author$project$Page$ForgotPassword$update, subMsg, subModel);
						var forgotPasswordModel = _v4.a;
						var cmd = _v4.b;
						var externalMsg = _v4.c;
						var newCmd = A2($elm$core$Platform$Cmd$map, $author$project$Main$ForgotPasswordMsg, cmd);
						var newModel = _Utils_update(
							model,
							{
								g: $author$project$Main$Loaded(
									$author$project$Main$ForgotPassword(forgotPasswordModel))
							});
						switch (externalMsg.$) {
							case 0:
								return _Utils_Tuple2(newModel, newCmd);
							case 1:
								var title = externalMsg.a;
								var message = externalMsg.b;
								return _Utils_Tuple2(
									A3(
										$author$project$Main$openDialog,
										$elm$core$Maybe$Just(title),
										message,
										newModel),
									A2($author$project$Route$newUrl, model.x, $author$project$Route$Login));
							default:
								var error = externalMsg.a;
								return _Utils_Tuple2(
									A2($author$project$Main$setError, error, newModel),
									newCmd);
						}
					} else {
						break _v0$24;
					}
				case 3:
					var subMsg = _v0.a.a;
					var _v6 = model.m;
					if (!_v6.$) {
						var _v7 = A2($author$project$Cases$update, subMsg, model.v);
						var casesModel = _v7.a;
						var cmd = _v7.b;
						var externalMsg = _v7.c;
						var newCmd = A2($elm$core$Platform$Cmd$map, $author$project$Main$CasesMsg, cmd);
						if (!externalMsg.$) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{v: casesModel, a0: true}),
								newCmd);
						} else {
							var error = externalMsg.a;
							return _Utils_Tuple2(
								A2($author$project$Main$setError, error, model),
								newCmd);
						}
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 4:
					var subMsg = _v0.a.a;
					var _v9 = model.m;
					if (!_v9.$) {
						var _v10 = A2($author$project$Search$update, subMsg, model.I);
						var searchModel = _v10.a;
						var cmd = _v10.b;
						var externalMsg = _v10.c;
						var newCmd = A2($elm$core$Platform$Cmd$map, $author$project$Main$SearchMsg, cmd);
						var newModel = _Utils_update(
							model,
							{I: searchModel});
						if (!externalMsg.$) {
							return _Utils_Tuple2(newModel, newCmd);
						} else {
							var caseId = externalMsg.a;
							return _Utils_Tuple2(
								newModel,
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											newCmd,
											A2(
											$author$project$Route$newUrl,
											model.x,
											$author$project$Route$Case(caseId))
										])));
						}
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 5:
					if (_v0.b.$ === 1) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						var _v12 = model.m;
						if (!_v12.$) {
							var _v13 = A2($author$project$Page$Home$update, subMsg, subModel);
							var homeModel = _v13.a;
							var cmd = _v13.b;
							var newCmd = A2($elm$core$Platform$Cmd$map, $author$project$Main$HomeMsg, cmd);
							var newModel = _Utils_update(
								model,
								{
									g: $author$project$Main$Loaded(
										$author$project$Main$Home(homeModel))
								});
							return _Utils_Tuple2(newModel, newCmd);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					} else {
						break _v0$24;
					}
				case 6:
					if (_v0.b.$ === 2) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						var _v14 = model.m;
						if (!_v14.$) {
							var user = _v14.a;
							var _v15 = A3($author$project$Page$Case$update, user, subMsg, subModel);
							var caseModel = _v15.a;
							var cmd = _v15.b;
							var externalMsg = _v15.c;
							var newModel = _Utils_update(
								model,
								{
									g: $author$project$Main$Loaded(
										$author$project$Main$Case(caseModel))
								});
							var newCmd = A2($elm$core$Platform$Cmd$map, $author$project$Main$CaseMsg, cmd);
							switch (externalMsg.$) {
								case 0:
									return _Utils_Tuple2(newModel, newCmd);
								case 1:
									var caseId = externalMsg.a;
									return _Utils_Tuple2(
										newModel,
										$elm$core$Platform$Cmd$batch(
											_List_fromArray(
												[
													newCmd,
													A2(
													$author$project$Route$newUrl,
													model.x,
													$author$project$Route$Case(caseId))
												])));
								default:
									var error = externalMsg.a;
									return _Utils_Tuple2(
										A2($author$project$Main$setError, error, newModel),
										newCmd);
							}
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					} else {
						break _v0$24;
					}
				case 7:
					if (_v0.b.$ === 3) {
						var subMsg = _v0.a.a;
						var subModel = _v0.b.a;
						var _v17 = model.m;
						if (!_v17.$) {
							var user = _v17.a;
							var _v18 = A3($author$project$Page$NewCase$update, user, subMsg, subModel);
							var newCaseModel = _v18.a;
							var cmd = _v18.b;
							var externalMsg = _v18.c;
							var newCmd = A2($elm$core$Platform$Cmd$map, $author$project$Main$NewCaseMsg, cmd);
							var newModel = _Utils_update(
								model,
								{
									g: $author$project$Main$Loaded(
										$author$project$Main$NewCase(newCaseModel))
								});
							switch (externalMsg.$) {
								case 0:
									return _Utils_Tuple2(newModel, newCmd);
								case 1:
									var caseId = externalMsg.a;
									return _Utils_Tuple2(
										newModel,
										$elm$core$Platform$Cmd$batch(
											_List_fromArray(
												[
													newCmd,
													A2(
													$author$project$Route$newUrl,
													model.x,
													$author$project$Route$Case(caseId))
												])));
								default:
									var error = externalMsg.a;
									return _Utils_Tuple2(
										A2($author$project$Main$setError, error, newModel),
										newCmd);
							}
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					} else {
						break _v0$24;
					}
				case 8:
					var encodedUser = _v0.a.a;
					var _v20 = A2($elm$json$Json$Decode$decodeString, $author$project$Type$User$fromStoreDecoder, encodedUser);
					if (!_v20.$) {
						var user = _v20.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									m: $author$project$Main$Logged(user)
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{m: $author$project$Main$NotLogged}),
							$elm$core$Platform$Cmd$none);
					}
				case 9:
					var _v21 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ap: false}),
						function () {
							var _v22 = model.m;
							if (!_v22.$) {
								var user = _v22.a;
								return A2(
									$author$project$Api$logout,
									A2($elm$http$Http$expectJson, $author$project$Main$LogoutCompleted, $author$project$Main$logoutDecoder),
									user);
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}());
				case 10:
					if (_v0.a.a.$ === 1) {
						return _Utils_Tuple2(
							A2($author$project$Main$setError, 'Odhlášení se nezdařilo.', model),
							$elm$core$Platform$Cmd$none);
					} else {
						var newModel = _Utils_update(
							model,
							{v: $author$project$Cases$initModel, I: $author$project$Search$initModel, m: $author$project$Main$NotLogged});
						return _Utils_Tuple2(
							newModel,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Type$User$logout,
										A2($author$project$Route$replaceUrl, model.x, $author$project$Route$Login)
									])));
					}
				case 12:
					var urlRequest = _v0.a.a;
					if (!urlRequest.$) {
						var url = urlRequest.a;
						return _Utils_Tuple2(
							model,
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								model.x,
								$elm$url$Url$toString(url)));
					} else {
						var href = urlRequest.a;
						return _Utils_Tuple2(
							model,
							$elm$browser$Browser$Navigation$load(href));
					}
				case 11:
					var url = _v0.a.a;
					var maybeRoute = $author$project$Route$fromUrl(url);
					return A2($author$project$Main$changeRouteTo, maybeRoute, model);
				case 13:
					if (!_v0.a.a.$) {
						var subModel = _v0.a.a.a;
						var newSubModel = _Utils_update(
							subModel,
							{
								v: $author$project$Cases$sortByLatest(subModel.v)
							});
						var _v24 = model.M;
						if (_v24 === 1) {
							var maybeLastCase = $elm$core$List$head(newSubModel.v);
							if (!maybeLastCase.$) {
								var lastCase = maybeLastCase.a;
								return _Utils_Tuple2(
									model,
									A2(
										$author$project$Route$newUrl,
										model.x,
										$author$project$Route$Case(lastCase.co)));
							} else {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											g: $author$project$Main$Loaded(
												$author$project$Main$Home(newSubModel))
										}),
									$elm$core$Platform$Cmd$none);
							}
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										g: $author$project$Main$Loaded(
											$author$project$Main$Home(newSubModel))
									}),
								$elm$core$Platform$Cmd$none);
						}
					} else {
						return _Utils_Tuple2(
							A2($author$project$Main$setError, 'Úvodní stránku se nepodařilo načíst.', model),
							$elm$core$Platform$Cmd$none);
					}
				case 14:
					if (!_v0.a.a.$) {
						var subModel = _v0.a.a.a;
						var _v26 = subModel.cv;
						if (!_v26.$) {
							var caseDetail = _v26.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										g: $author$project$Main$Loaded(
											$author$project$Main$Case(
												A2($author$project$Page$Case$initModel, caseDetail, subModel.J))),
										I: $author$project$Search$initModel
									}),
								A2(
									$elm$core$Platform$Cmd$map,
									$author$project$Main$CaseMsg,
									A2($author$project$Page$Case$scrollTo, subModel.cv, model.I.d1)));
						} else {
							return _Utils_Tuple2(
								A2($author$project$Main$setError, 'Případ nebyl nalezen.', model),
								$elm$core$Platform$Cmd$none);
						}
					} else {
						return _Utils_Tuple2(
							A2($author$project$Main$setError, 'Případ se nepodařilo načíst.', model),
							$elm$core$Platform$Cmd$none);
					}
				case 15:
					var _v27 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ap: true}),
						$elm$core$Platform$Cmd$none);
				case 16:
					var _v28 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ap: false}),
						$elm$core$Platform$Cmd$none);
				case 17:
					var posix = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{J: posix}),
						$elm$core$Platform$Cmd$none);
				case 18:
					var _v29 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{au: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none);
				case 19:
					var _v30 = _v0.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aw: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none);
				default:
					var width = _v0.a.a;
					var newModel = A2($author$project$Main$setDevice, width, model);
					var _v31 = _Utils_Tuple3(model.ac, model.M, newModel.M);
					if ((((!_v31.a.$) && (!_v31.a.a.$)) && (!_v31.b)) && (_v31.c === 1)) {
						var _v32 = _v31.a.a;
						var _v33 = _v31.b;
						var _v34 = _v31.c;
						return A2($author$project$Main$changeRouteTo, model.ac, newModel);
					} else {
						return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
					}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Main$pageTitle = function (pageState) {
	var _v0 = $author$project$Main$getPage(pageState);
	switch (_v0.$) {
		case 0:
			return 'Avogado';
		case 1:
			return 'Avogado';
		case 2:
			var caseDetail = _v0.a.dg;
			return caseDetail.ed + ' - Avogado';
		case 3:
			return 'Nový požadavek - Avogado';
		case 4:
			return 'Přihlášení - Avogado';
		default:
			return 'Zapomněli jste heslo? - Avogado';
	}
};
var $author$project$Main$CloseDialog = {$: 18};
var $author$project$Main$CloseErrorDialog = {$: 19};
var $aforemny$material_components_web_elm$Material$Button$Internal$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Button$config = {ar: _List_Nil, a7: false, av: false, _: $elm$core$Maybe$Nothing, be: $elm$core$Maybe$Nothing, bu: $elm$core$Maybe$Nothing, bQ: $elm$core$Maybe$Nothing, aT: false};
var $aforemny$material_components_web_elm$Material$Dialog$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Dialog$config = {ar: _List_Nil, bv: $elm$core$Maybe$Nothing, by: false};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $aforemny$material_components_web_elm$Material$Dialog$ariaModalAttr = $elm$core$Maybe$Just(
	A2($elm$html$Html$Attributes$attribute, 'aria-modal', 'true'));
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $aforemny$material_components_web_elm$Material$Dialog$closeHandler = function (_v0) {
	var onClose = _v0.bv;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Events$on('MDCDialog:close'),
			$elm$json$Json$Decode$succeed),
		onClose);
};
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $aforemny$material_components_web_elm$Material$Dialog$actionsElt = function (_v0) {
	var actions = _v0.b$;
	return $elm$core$List$isEmpty(actions) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-dialog__actions')
				]),
			actions));
};
var $aforemny$material_components_web_elm$Material$Dialog$contentElt = function (_v0) {
	var content = _v0.b9;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-dialog__content')
				]),
			content));
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $aforemny$material_components_web_elm$Material$Dialog$titleElt = function (_v0) {
	var title = _v0.ed;
	if (!title.$) {
		var title_ = title.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-dialog__title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(title_)
					])));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $aforemny$material_components_web_elm$Material$Dialog$surfaceElt = function (content) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-dialog__surface')
			]),
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$Dialog$titleElt(content),
					$aforemny$material_components_web_elm$Material$Dialog$contentElt(content),
					$aforemny$material_components_web_elm$Material$Dialog$actionsElt(content)
				])));
};
var $aforemny$material_components_web_elm$Material$Dialog$containerElt = function (content) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-dialog__container')
			]),
		_List_fromArray(
			[
				$aforemny$material_components_web_elm$Material$Dialog$surfaceElt(content)
			]));
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$property = $elm$virtual_dom$VirtualDom$property;
var $aforemny$material_components_web_elm$Material$Dialog$openProp = function (_v0) {
	var open = _v0.by;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'open',
			$elm$json$Json$Encode$bool(open)));
};
var $aforemny$material_components_web_elm$Material$Dialog$roleAttr = $elm$core$Maybe$Just(
	A2($elm$html$Html$Attributes$attribute, 'role', 'alertdialog'));
var $aforemny$material_components_web_elm$Material$Dialog$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-dialog'));
var $aforemny$material_components_web_elm$Material$Dialog$scrimElt = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-dialog__scrim')
		]),
	_List_Nil);
var $aforemny$material_components_web_elm$Material$Dialog$dialog = F2(
	function (config_, content) {
		var additionalAttributes = config_.ar;
		return A3(
			$elm$html$Html$node,
			'mdc-dialog',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Dialog$rootCs,
							$aforemny$material_components_web_elm$Material$Dialog$openProp(config_),
							$aforemny$material_components_web_elm$Material$Dialog$roleAttr,
							$aforemny$material_components_web_elm$Material$Dialog$ariaModalAttr,
							$aforemny$material_components_web_elm$Material$Dialog$closeHandler(config_)
						])),
				additionalAttributes),
			_List_fromArray(
				[
					$aforemny$material_components_web_elm$Material$Dialog$containerElt(content),
					$aforemny$material_components_web_elm$Material$Dialog$scrimElt
				]));
	});
var $aforemny$material_components_web_elm$Material$Button$setOnClick = F2(
	function (onClick, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				bu: $elm$core$Maybe$Just(onClick)
			});
	});
var $aforemny$material_components_web_elm$Material$Dialog$setOnClose = F2(
	function (onClose, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				bv: $elm$core$Maybe$Just(onClose)
			});
	});
var $aforemny$material_components_web_elm$Material$Dialog$setOpen = F2(
	function (open, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{by: open});
	});
var $aforemny$material_components_web_elm$Material$Button$Text = 0;
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $aforemny$material_components_web_elm$Material$Button$clickHandler = function (_v0) {
	var onClick = _v0.bu;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, onClick);
};
var $aforemny$material_components_web_elm$Material$Button$denseCs = function (_v0) {
	var dense = _v0.a7;
	return dense ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-button--dense')) : $elm$core$Maybe$Nothing;
};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $aforemny$material_components_web_elm$Material$Button$disabledAttr = function (_v0) {
	var disabled = _v0.av;
	return $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$disabled(disabled));
};
var $aforemny$material_components_web_elm$Material$Button$disabledProp = function (_v0) {
	var disabled = _v0.av;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'disabled',
			$elm$json$Json$Encode$bool(disabled)));
};
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $aforemny$material_components_web_elm$Material$Button$hrefAttr = function (_v0) {
	var href = _v0._;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$href, href);
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $aforemny$material_components_web_elm$Material$Button$labelElt = function (label) {
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-button__label')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(label)
				])));
};
var $elm$html$Html$i = _VirtualDom_node('i');
var $aforemny$material_components_web_elm$Material$Button$iconElt = function (_v0) {
	var icon = _v0.be;
	return A2(
		$elm$core$Maybe$map,
		function (iconName) {
			return A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mdc-button__icon material-icons'),
						A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(iconName)
					]));
		},
		icon);
};
var $aforemny$material_components_web_elm$Material$Button$leadingIconElt = function (config_) {
	var trailingIcon = config_.aT;
	return (!trailingIcon) ? $aforemny$material_components_web_elm$Material$Button$iconElt(config_) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Button$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-button'));
var $aforemny$material_components_web_elm$Material$Button$tabIndexProp = function (_v0) {
	var disabled = _v0.av;
	return disabled ? $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'tabIndex',
			$elm$json$Json$Encode$int(-1))) : $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'tabIndex',
			$elm$json$Json$Encode$int(0)));
};
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $aforemny$material_components_web_elm$Material$Button$targetAttr = function (_v0) {
	var href = _v0._;
	var target = _v0.bQ;
	return (!_Utils_eq(href, $elm$core$Maybe$Nothing)) ? A2($elm$core$Maybe$map, $elm$html$Html$Attributes$target, target) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Button$trailingIconElt = function (config_) {
	var trailingIcon = config_.aT;
	return trailingIcon ? $aforemny$material_components_web_elm$Material$Button$iconElt(config_) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$Button$variantCs = function (variant) {
	switch (variant) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-button--raised'));
		case 2:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-button--unelevated'));
		default:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('mdc-button--outlined'));
	}
};
var $aforemny$material_components_web_elm$Material$Button$button = F3(
	function (variant, config_, label) {
		var additionalAttributes = config_.ar;
		var href = config_._;
		return A3(
			$elm$html$Html$node,
			'mdc-button',
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						$aforemny$material_components_web_elm$Material$Button$disabledProp(config_)
					])),
			_List_fromArray(
				[
					A2(
					(!_Utils_eq(href, $elm$core$Maybe$Nothing)) ? $elm$html$Html$a : $elm$html$Html$button,
					_Utils_ap(
						A2(
							$elm$core$List$filterMap,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$aforemny$material_components_web_elm$Material$Button$rootCs,
									$aforemny$material_components_web_elm$Material$Button$variantCs(variant),
									$aforemny$material_components_web_elm$Material$Button$denseCs(config_),
									$aforemny$material_components_web_elm$Material$Button$disabledAttr(config_),
									$aforemny$material_components_web_elm$Material$Button$tabIndexProp(config_),
									$aforemny$material_components_web_elm$Material$Button$hrefAttr(config_),
									$aforemny$material_components_web_elm$Material$Button$targetAttr(config_),
									$aforemny$material_components_web_elm$Material$Button$clickHandler(config_)
								])),
						additionalAttributes),
					A2(
						$elm$core$List$filterMap,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								$aforemny$material_components_web_elm$Material$Button$leadingIconElt(config_),
								$aforemny$material_components_web_elm$Material$Button$labelElt(label),
								$aforemny$material_components_web_elm$Material$Button$trailingIconElt(config_)
							])))
				]));
	});
var $aforemny$material_components_web_elm$Material$Button$text = F2(
	function (config_, label) {
		return A3($aforemny$material_components_web_elm$Material$Button$button, 0, config_, label);
	});
var $author$project$Main$viewDialog = function (maybeDialog) {
	if (!maybeDialog.$) {
		var title = maybeDialog.a.ed;
		var message = maybeDialog.a.bi;
		return A2(
			$aforemny$material_components_web_elm$Material$Dialog$dialog,
			A2(
				$aforemny$material_components_web_elm$Material$Dialog$setOnClose,
				$author$project$Main$CloseErrorDialog,
				A2($aforemny$material_components_web_elm$Material$Dialog$setOpen, true, $aforemny$material_components_web_elm$Material$Dialog$config)),
			{
				b$: _List_fromArray(
					[
						A2(
						$aforemny$material_components_web_elm$Material$Button$text,
						A2($aforemny$material_components_web_elm$Material$Button$setOnClick, $author$project$Main$CloseDialog, $aforemny$material_components_web_elm$Material$Button$config),
						'OK')
					]),
				b9: _List_fromArray(
					[
						$elm$html$Html$text(message)
					]),
				ed: title
			});
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Main$viewErrorDialog = function (maybeError) {
	if (!maybeError.$) {
		var error = maybeError.a;
		return A2(
			$aforemny$material_components_web_elm$Material$Dialog$dialog,
			A2(
				$aforemny$material_components_web_elm$Material$Dialog$setOnClose,
				$author$project$Main$CloseErrorDialog,
				A2($aforemny$material_components_web_elm$Material$Dialog$setOpen, true, $aforemny$material_components_web_elm$Material$Dialog$config)),
			{
				b$: _List_fromArray(
					[
						A2(
						$aforemny$material_components_web_elm$Material$Button$text,
						A2($aforemny$material_components_web_elm$Material$Button$setOnClick, $author$project$Main$CloseErrorDialog, $aforemny$material_components_web_elm$Material$Button$config),
						'OK')
					]),
				b9: _List_fromArray(
					[
						$elm$html$Html$text(error)
					]),
				ed: $elm$core$Maybe$Nothing
			});
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Main$Logout = {$: 9};
var $author$project$Main$MenuClosed = {$: 16};
var $author$project$Main$MenuOpened = {$: 15};
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $elm$svg$Svg$Attributes$strokeWidth = _VirtualDom_attribute('stroke-width');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $author$project$SvgObject$loader = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$class('MuiCircularProgress-svg'),
			$elm$svg$Svg$Attributes$viewBox('22 22 44 44')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate'),
					$elm$svg$Svg$Attributes$cx('44'),
					$elm$svg$Svg$Attributes$cy('44'),
					$elm$svg$Svg$Attributes$r('20.2'),
					$elm$svg$Svg$Attributes$fill('none'),
					$elm$svg$Svg$Attributes$strokeWidth('3.6')
				]),
			_List_Nil)
		]));
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Layout$loader = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('loader-view w-100 h-100 bg-white')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate'),
					A2($elm$html$Html$Attributes$style, 'width', '40px'),
					A2($elm$html$Html$Attributes$style, 'height', '40px')
				]),
			_List_fromArray(
				[$author$project$SvgObject$loader]))
		]));
var $author$project$Layout$appModule = F3(
	function (maybeSidebar, content, isLoading) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('app-module chat-module')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('chat-module-box')
						]),
					_List_fromArray(
						[
							function () {
							if (!maybeSidebar.$) {
								var sidebar = maybeSidebar.a;
								return A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('chat-sidenav d-none d-md-flex')
										]),
									_List_fromArray(
										[
											_Utils_eq(
											sidebar,
											$elm$html$Html$text('')) ? $author$project$Layout$loader : A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('chat-sidenav-main')
												]),
											_List_fromArray(
												[sidebar]))
										]));
							} else {
								return $elm$html$Html$text('');
							}
						}(),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('chat-box')
								]),
							_List_fromArray(
								[
									isLoading ? $author$project$Layout$loader : content
								]))
						]))
				]));
	});
var $elm$html$Html$header = _VirtualDom_node('header');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$main_ = _VirtualDom_node('main');
var $author$project$Layout$app = F5(
	function (isLoading, navbar, maybeSidebar, search, content) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('app-site'),
					$elm$html$Html$Attributes$class('layout-app')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('app-main')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('app-container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('app-main-container')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('app-header')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$header,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('MuiPaper-root MuiAppBar-root MuiAppBar-positionFixed MuiAppBar-colorPrimary app-main-header mui-fixed')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('MuiToolbar-root MuiToolbar-regular app-toolbar MuiToolbar-gutters')
																]),
															_List_fromArray(
																[navbar]))
														]))
												])),
											A2(
											$elm$html$Html$main_,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('app-main-content-wrapper')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('app-main-content')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('app-wrapper app-wrapper-module')
																]),
															_List_fromArray(
																[
																	A3($author$project$Layout$appModule, maybeSidebar, content, isLoading)
																]))
														])),
													search
												]))
										]))
								]))
						]))
				]));
	});
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $aforemny$material_components_web_elm$Material$IconButton$Internal$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$IconButton$config = {ar: _List_Nil, av: false, aa: $elm$core$Maybe$Nothing, bu: $elm$core$Maybe$Nothing};
var $aforemny$material_components_web_elm$Material$List$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$List$config = {ar: _List_Nil, a_: false, a7: false, bp: false, bT: false, c2: false, b_: false};
var $aforemny$material_components_web_elm$Material$List$Divider$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$List$Divider$config = {ar: _List_Nil, bf: false, bB: false};
var $aforemny$material_components_web_elm$Material$List$Item$Internal$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$List$Item$config = {
	ar: _List_Nil,
	av: false,
	_: $elm$core$Maybe$Nothing,
	dK: $elm$html$Html$text(''),
	bu: $elm$core$Maybe$Nothing,
	d2: $elm$core$Maybe$Nothing,
	bQ: $elm$core$Maybe$Nothing
};
var $aforemny$material_components_web_elm$Material$Menu$Config = $elm$core$Basics$identity;
var $aforemny$material_components_web_elm$Material$Menu$config = {ar: _List_Nil, bv: $elm$core$Maybe$Nothing, by: false, bF: false};
var $author$project$Route$href = function (targetRoute) {
	return $elm$html$Html$Attributes$href(
		$author$project$Route$routeToString(targetRoute));
};
var $aforemny$material_components_web_elm$Material$Icon$icon = F2(
	function (additionalAttributes, iconName) {
		return A2(
			$elm$html$Html$i,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('material-icons'),
				additionalAttributes),
			_List_fromArray(
				[
					$elm$html$Html$text(iconName)
				]));
	});
var $aforemny$material_components_web_elm$Material$IconButton$clickHandler = function (_v0) {
	var onClick = _v0.bu;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, onClick);
};
var $aforemny$material_components_web_elm$Material$IconButton$materialIconsCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('material-icons'));
var $aforemny$material_components_web_elm$Material$IconButton$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-icon-button'));
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $aforemny$material_components_web_elm$Material$IconButton$tabIndexProp = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$tabindex(0));
var $aforemny$material_components_web_elm$Material$IconButton$iconButton = F2(
	function (config_, iconName) {
		var additionalAttributes = config_.ar;
		return A3(
			$elm$html$Html$node,
			'mdc-icon-button',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$IconButton$rootCs,
							$aforemny$material_components_web_elm$Material$IconButton$materialIconsCs,
							$aforemny$material_components_web_elm$Material$IconButton$tabIndexProp,
							$aforemny$material_components_web_elm$Material$IconButton$clickHandler(config_)
						])),
				additionalAttributes),
			_List_fromArray(
				[
					$elm$html$Html$text(iconName)
				]));
	});
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$String$toUpper = _String_toUpper;
var $author$project$Util$initials = function (name) {
	var nameParts = A2($elm$core$String$split, ' ', name);
	var second = A2(
		$elm$core$String$left,
		1,
		A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(
				$elm$core$List$reverse(
					A2($elm$core$List$drop, 1, nameParts)))));
	var first = A2(
		$elm$core$String$left,
		1,
		A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(nameParts)));
	return $elm$core$String$toUpper(
		_Utils_ap(first, second));
};
var $aforemny$material_components_web_elm$Material$List$avatarListCs = function (_v0) {
	var avatarList = _v0.a_;
	return avatarList ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-list--avatar-list')) : $elm$core$Maybe$Nothing;
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $aforemny$material_components_web_elm$Material$List$clickHandler = function (listItems) {
	var getOnClick = function (listItem_) {
		switch (listItem_.$) {
			case 0:
				var onClick = listItem_.a.bu;
				return $elm$core$Maybe$Just(onClick);
			case 1:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Nothing;
		}
	};
	var nthOnClick = function (index) {
		return A2(
			$elm$core$Maybe$andThen,
			$elm$core$Basics$identity,
			$elm$core$List$head(
				A2(
					$elm$core$List$drop,
					index,
					A2(
						$elm$core$List$filterMap,
						$elm$core$Basics$identity,
						A2($elm$core$List$map, getOnClick, listItems)))));
	};
	var mergedClickHandler = A2(
		$elm$json$Json$Decode$andThen,
		function (index) {
			var _v0 = nthOnClick(index);
			if (!_v0.$) {
				var msg_ = _v0.a;
				return $elm$json$Json$Decode$succeed(msg_);
			} else {
				return $elm$json$Json$Decode$fail('');
			}
		},
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['detail', 'index']),
			$elm$json$Json$Decode$int));
	return $elm$core$Maybe$Just(
		A2($elm$html$Html$Events$on, 'MDCList:action', mergedClickHandler));
};
var $aforemny$material_components_web_elm$Material$List$denseCs = function (_v0) {
	var dense = _v0.a7;
	return dense ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-list--dense')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$nonInteractiveCs = function (_v0) {
	var nonInteractive = _v0.bp;
	return nonInteractive ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-list--non-interactive')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-list'));
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $aforemny$material_components_web_elm$Material$List$selectedIndexProp = function (listItems) {
	var selectedIndex = A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2(
			$elm$core$List$indexedMap,
			F2(
				function (index, listItem_) {
					switch (listItem_.$) {
						case 0:
							var selection = listItem_.a.d2;
							return (!_Utils_eq(selection, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just(index) : $elm$core$Maybe$Nothing;
						case 1:
							return $elm$core$Maybe$Nothing;
						default:
							return $elm$core$Maybe$Nothing;
					}
				}),
			A2(
				$elm$core$List$filter,
				function (listItem_) {
					switch (listItem_.$) {
						case 0:
							return true;
						case 1:
							return false;
						default:
							return false;
					}
				},
				listItems)));
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'selectedIndex',
			A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, selectedIndex)));
};
var $aforemny$material_components_web_elm$Material$List$twoLineCs = function (_v0) {
	var twoLine = _v0.bT;
	return twoLine ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-list--two-line')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$wrapFocusProp = function (_v0) {
	var wrapFocus = _v0.b_;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'wrapFocus',
			$elm$json$Json$Encode$bool(wrapFocus)));
};
var $aforemny$material_components_web_elm$Material$List$list = F2(
	function (config_, listItems) {
		var additionalAttributes = config_.ar;
		return A3(
			$elm$html$Html$node,
			'mdc-list',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$List$rootCs,
							$aforemny$material_components_web_elm$Material$List$nonInteractiveCs(config_),
							$aforemny$material_components_web_elm$Material$List$denseCs(config_),
							$aforemny$material_components_web_elm$Material$List$avatarListCs(config_),
							$aforemny$material_components_web_elm$Material$List$twoLineCs(config_),
							$aforemny$material_components_web_elm$Material$List$wrapFocusProp(config_),
							$aforemny$material_components_web_elm$Material$List$clickHandler(listItems),
							$aforemny$material_components_web_elm$Material$List$selectedIndexProp(listItems)
						])),
				additionalAttributes),
			A2(
				$elm$core$List$map,
				function (listItem_) {
					switch (listItem_.$) {
						case 0:
							var node = listItem_.a.dK;
							return node;
						case 1:
							var node = listItem_.a;
							return node;
						default:
							var node = listItem_.a;
							return node;
					}
				},
				listItems));
	});
var $aforemny$material_components_web_elm$Material$List$Item$Internal$ListItemDivider = function (a) {
	return {$: 1, a: a};
};
var $aforemny$material_components_web_elm$Material$List$Divider$insetCs = function (_v0) {
	var inset = _v0.bf;
	return inset ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-list-divider--inset')) : $elm$core$Maybe$Nothing;
};
var $elm$html$Html$li = _VirtualDom_node('li');
var $aforemny$material_components_web_elm$Material$List$Divider$listDividerCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-list-divider'));
var $aforemny$material_components_web_elm$Material$List$Divider$paddedCs = function (_v0) {
	var padded = _v0.bB;
	return padded ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-list-divider--padded')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$Divider$separatorRoleAttr = $elm$core$Maybe$Just(
	A2($elm$html$Html$Attributes$attribute, 'role', 'separator'));
var $aforemny$material_components_web_elm$Material$List$Divider$listItem = function (config_) {
	var additionalAttributes = config_.ar;
	return $aforemny$material_components_web_elm$Material$List$Item$Internal$ListItemDivider(
		A2(
			$elm$html$Html$li,
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$List$Divider$listDividerCs,
							$aforemny$material_components_web_elm$Material$List$Divider$separatorRoleAttr,
							$aforemny$material_components_web_elm$Material$List$Divider$insetCs(config_),
							$aforemny$material_components_web_elm$Material$List$Divider$paddedCs(config_)
						])),
				additionalAttributes),
			_List_Nil));
};
var $aforemny$material_components_web_elm$Material$List$Item$Internal$ListItem = function (a) {
	return {$: 0, a: a};
};
var $aforemny$material_components_web_elm$Material$List$Item$Internal$Activated = 1;
var $aforemny$material_components_web_elm$Material$List$Item$activatedCs = function (_v0) {
	var selection = _v0.d2;
	return _Utils_eq(
		selection,
		$elm$core$Maybe$Just(1)) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-list-item--activated')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$Item$ariaSelectedAttr = function (_v0) {
	var selection = _v0.d2;
	return (!_Utils_eq(selection, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just(
		A2($elm$html$Html$Attributes$attribute, 'aria-selected', 'true')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$Item$disabledCs = function (_v0) {
	var disabled = _v0.av;
	return disabled ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-list-item--disabled')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$Item$hrefAttr = function (_v0) {
	var href = _v0._;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$href, href);
};
var $aforemny$material_components_web_elm$Material$List$Item$listItemCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-list-item'));
var $aforemny$material_components_web_elm$Material$List$Item$Internal$Selected = 0;
var $aforemny$material_components_web_elm$Material$List$Item$selectedCs = function (_v0) {
	var selection = _v0.d2;
	return _Utils_eq(
		selection,
		$elm$core$Maybe$Just(0)) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-list-item--selected')) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$Item$targetAttr = function (_v0) {
	var href = _v0._;
	var target = _v0.bQ;
	return (!_Utils_eq(href, $elm$core$Maybe$Nothing)) ? A2($elm$core$Maybe$map, $elm$html$Html$Attributes$target, target) : $elm$core$Maybe$Nothing;
};
var $aforemny$material_components_web_elm$Material$List$Item$listItemView = F2(
	function (config_, nodes) {
		var additionalAttributes = config_.ar;
		var href = config_._;
		return function (attributes) {
			return (!_Utils_eq(href, $elm$core$Maybe$Nothing)) ? A3(
				$elm$html$Html$node,
				'mdc-list-item',
				_List_Nil,
				_List_fromArray(
					[
						A2($elm$html$Html$a, attributes, nodes)
					])) : A3($elm$html$Html$node, 'mdc-list-item', attributes, nodes);
		}(
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$List$Item$listItemCs,
							$aforemny$material_components_web_elm$Material$List$Item$hrefAttr(config_),
							$aforemny$material_components_web_elm$Material$List$Item$targetAttr(config_),
							$aforemny$material_components_web_elm$Material$List$Item$disabledCs(config_),
							$aforemny$material_components_web_elm$Material$List$Item$selectedCs(config_),
							$aforemny$material_components_web_elm$Material$List$Item$activatedCs(config_),
							$aforemny$material_components_web_elm$Material$List$Item$ariaSelectedAttr(config_)
						])),
				additionalAttributes));
	});
var $aforemny$material_components_web_elm$Material$List$Item$listItem = F2(
	function (_v0, nodes) {
		var config_ = _v0;
		var additionalAttributes = config_.ar;
		var href = config_._;
		return $aforemny$material_components_web_elm$Material$List$Item$Internal$ListItem(
			_Utils_update(
				config_,
				{
					dK: A2($aforemny$material_components_web_elm$Material$List$Item$listItemView, config_, nodes)
				}));
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $aforemny$material_components_web_elm$Material$Menu$closeHandler = function (_v0) {
	var onClose = _v0.bv;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Events$on('MDCMenu:close'),
			$elm$json$Json$Decode$succeed),
		onClose);
};
var $aforemny$material_components_web_elm$Material$Menu$openProp = function (_v0) {
	var open = _v0.by;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'open',
			$elm$json$Json$Encode$bool(open)));
};
var $aforemny$material_components_web_elm$Material$Menu$quickOpenProp = function (_v0) {
	var quickOpen = _v0.bF;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'quickOpen',
			$elm$json$Json$Encode$bool(quickOpen)));
};
var $aforemny$material_components_web_elm$Material$Menu$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-menu mdc-menu-surface'));
var $aforemny$material_components_web_elm$Material$Menu$menu = F2(
	function (config_, nodes) {
		var additionalAttributes = config_.ar;
		return A3(
			$elm$html$Html$node,
			'mdc-menu',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$aforemny$material_components_web_elm$Material$Menu$rootCs,
							$aforemny$material_components_web_elm$Material$Menu$openProp(config_),
							$aforemny$material_components_web_elm$Material$Menu$quickOpenProp(config_),
							$aforemny$material_components_web_elm$Material$Menu$closeHandler(config_)
						])),
				additionalAttributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$List$Item$meta = F2(
	function (additionalAttributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-list-item__meta'),
				additionalAttributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$List$Item$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ar: additionalAttributes});
	});
var $aforemny$material_components_web_elm$Material$List$Item$setHref = F2(
	function (href, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{_: href});
	});
var $aforemny$material_components_web_elm$Material$List$Item$setOnClick = F2(
	function (onClick, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				bu: $elm$core$Maybe$Just(onClick)
			});
	});
var $aforemny$material_components_web_elm$Material$Menu$setOnClose = F2(
	function (onClose, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				bv: $elm$core$Maybe$Just(onClose)
			});
	});
var $aforemny$material_components_web_elm$Material$Menu$setOpen = F2(
	function (open, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{by: open});
	});
var $aforemny$material_components_web_elm$Material$List$setWrapFocus = F2(
	function (wrapFocus, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{b_: wrapFocus});
	});
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $aforemny$material_components_web_elm$Material$Menu$surfaceAnchor = $elm$html$Html$Attributes$class('mdc-menu-surface--anchor');
var $aforemny$material_components_web_elm$Material$Button$Raised = 1;
var $aforemny$material_components_web_elm$Material$Button$raised = F2(
	function (config_, label) {
		return A3($aforemny$material_components_web_elm$Material$Button$button, 1, config_, label);
	});
var $aforemny$material_components_web_elm$Material$Button$setHref = F2(
	function (href, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{_: href});
	});
var $aforemny$material_components_web_elm$Material$Button$setIcon = F2(
	function (icon, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{be: icon});
	});
var $author$project$DateTime$dayBefore = function (posix) {
	return $elm$time$Time$millisToPosix(
		$elm$time$Time$posixToMillis(posix) - 86400000);
};
var $justinmimbs$time_extra$Time$Extra$posixToParts = F2(
	function (zone, posix) {
		return {
			U: A2($elm$time$Time$toDay, zone, posix),
			aA: A2($elm$time$Time$toHour, zone, posix),
			cx: A2($elm$time$Time$toMillis, zone, posix),
			aJ: A2($elm$time$Time$toMinute, zone, posix),
			ad: A2($elm$time$Time$toMonth, zone, posix),
			aR: A2($elm$time$Time$toSecond, zone, posix),
			aq: A2($elm$time$Time$toYear, zone, posix)
		};
	});
var $author$project$DateTime$dayStart = function (posix) {
	var parts = A2($justinmimbs$time_extra$Time$Extra$posixToParts, $author$project$DateTime$zone, posix);
	return A2(
		$justinmimbs$time_extra$Time$Extra$partsToPosix,
		$author$project$DateTime$zone,
		{U: parts.U, aA: 0, cx: 0, aJ: 0, ad: parts.ad, aR: 0, aq: parts.aq});
};
var $author$project$DateTime$formatTodayYesterday = F2(
	function (dateTime, now) {
		var yesterday = $elm$time$Time$posixToMillis(
			$author$project$DateTime$dayStart(
				$author$project$DateTime$dayBefore(now)));
		var today = $elm$time$Time$posixToMillis(
			$author$project$DateTime$dayStart(now));
		var date = $elm$time$Time$posixToMillis(
			$author$project$DateTime$dayStart(dateTime));
		return _Utils_eq(date, today) ? $elm$core$Maybe$Just('dnes') : (_Utils_eq(date, yesterday) ? $elm$core$Maybe$Just('včera') : $elm$core$Maybe$Nothing);
	});
var $author$project$DateTime$toMonthInt = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $author$project$DateTime$formatDate = F2(
	function (posix, now) {
		var year = $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, $author$project$DateTime$zone, posix));
		var month = $elm$core$String$fromInt(
			$author$project$DateTime$toMonthInt(
				A2($elm$time$Time$toMonth, $author$project$DateTime$zone, posix)));
		var day = $elm$core$String$fromInt(
			A2($elm$time$Time$toDay, $author$project$DateTime$zone, posix));
		var _v0 = A2($author$project$DateTime$formatTodayYesterday, posix, now);
		if (!_v0.$) {
			var todayYeasterday = _v0.a;
			return todayYeasterday;
		} else {
			return A2(
				$elm$core$String$join,
				'. ',
				_List_fromArray(
					[day, month, year]));
		}
	});
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $author$project$DateTime$formatTime = function (posix) {
	var minute = A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(
			A2($elm$time$Time$toMinute, $author$project$DateTime$zone, posix)));
	var hour = $elm$core$String$fromInt(
		A2($elm$time$Time$toHour, $author$project$DateTime$zone, posix));
	return A2(
		$elm$core$String$join,
		':',
		_List_fromArray(
			[hour, minute]));
};
var $author$project$DateTime$format = F2(
	function (posix, now) {
		return A2($author$project$DateTime$formatDate, posix, now) + (' ' + $author$project$DateTime$formatTime(posix));
	});
var $author$project$Type$Case$initials = F2(
	function (maybeInitials, name) {
		if (!maybeInitials.$) {
			var initilas = maybeInitials.a;
			return initilas;
		} else {
			return $author$project$Util$initials(name);
		}
	});
var $author$project$Cases$viewAvatar = function (caseItem) {
	var maybeLastHistory = $elm$core$List$head(
		$elm$core$List$reverse(caseItem.dy));
	if (!maybeLastHistory.$) {
		var lastHistory = maybeLastHistory.a;
		var _v1 = lastHistory.aZ.dd;
		if (!_v1.$) {
			var avatar = _v1.a;
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(avatar),
						$elm$html$Html$Attributes$class('rounded-circle size-40'),
						$elm$html$Html$Attributes$alt('')
					]),
				_List_Nil);
		} else {
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('avatar-initials rounded-circle size-40'),
						$elm$html$Html$Attributes$alt('')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($author$project$Type$Case$initials, lastHistory.aZ.dD, lastHistory.aZ.dI))
					]));
		}
	} else {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('avatar-initials rounded-circle size-40'),
					$elm$html$Html$Attributes$alt('')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(caseItem.db)
				]));
	}
};
var $author$project$Cases$viewCaseItem = F3(
	function (maybeCurrentRoute, timeNow, caseItem) {
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('chat-user-item'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'active',
							_Utils_eq(
								maybeCurrentRoute,
								$elm$core$Maybe$Just(
									$author$project$Route$Case(caseItem.co))))
						])),
					$author$project$Route$href(
					$author$project$Route$Case(caseItem.co))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('chat-user-row row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('chat-avatar')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('chat-avatar-mode')
										]),
									_List_fromArray(
										[
											$author$project$Cases$viewAvatar(caseItem)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('chat-info')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('name h4')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(caseItem.ed)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('chat-info-des')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(caseItem.d4)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('last-message-time')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											A2($author$project$DateTime$format, caseItem.c0, timeNow))
										]))
								]))
						]))
				]));
	});
var $author$project$Cases$view = F3(
	function (cases, maybeCurrentRoute, timeNow) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('chat-sidenav')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('chat-sidenav-main')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('module-side-header new-case-btn-wrap')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('module-logo')
										]),
									_List_fromArray(
										[
											A2(
											$aforemny$material_components_web_elm$Material$Button$raised,
											A2(
												$aforemny$material_components_web_elm$Material$Button$setIcon,
												$elm$core$Maybe$Just('add'),
												A2(
													$aforemny$material_components_web_elm$Material$Button$setHref,
													$elm$core$Maybe$Just(
														$author$project$Route$routeToString($author$project$Route$NewCase)),
													$aforemny$material_components_web_elm$Material$Button$config)),
											'Nový požadavek')
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('chat-sidenav-content')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('scrollbar')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('scrollbar-inner')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('chat-user')
														]),
													A2(
														$elm$core$List$map,
														A2($author$project$Cases$viewCaseItem, maybeCurrentRoute, timeNow),
														cases))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Case$Expanded = 1;
var $author$project$Page$Case$SetFormSize = function (a) {
	return {$: 10, a: a};
};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $author$project$Page$Case$OpenFileClicked = {$: 2};
var $author$project$Page$Case$RemoveFile = {$: 5};
var $author$project$Page$Case$SetDescription = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Case$SubmitForm = {$: 8};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $author$project$Page$Case$canSubmitForm = function (form) {
	return A2(
		$elm$core$List$all,
		$elm$core$Basics$not,
		_List_fromArray(
			[
				$elm$core$String$isEmpty(
				$elm$core$String$trim(form.V)),
				form.ah
			]));
};
var $elm$html$Html$form = _VirtualDom_node('form');
var $author$project$Page$Case$loader = function (size) {
	var sizePx = $elm$core$String$fromInt(size) + 'px';
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('loader-view w-100 h-100'),
				A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
				A2($elm$html$Html$Attributes$style, 'top', '0px'),
				A2($elm$html$Html$Attributes$style, 'left', '0px'),
				A2($elm$html$Html$Attributes$style, 'background', 'rgba(255,255,255,0.5)')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate'),
						A2($elm$html$Html$Attributes$style, 'width', sizePx),
						A2($elm$html$Html$Attributes$style, 'height', sizePx)
					]),
				_List_fromArray(
					[$author$project$SvgObject$loader]))
			]));
};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $aforemny$material_components_web_elm$Material$Button$setDisabled = F2(
	function (disabled, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{av: disabled});
	});
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$Page$Case$viewForm = function (form) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('chat-main-footer chat-form-wrap')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('d-flex flex-row chat-form'),
						$elm$html$Html$Events$onSubmit($author$project$Page$Case$SubmitForm)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-message')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-group form-group-message')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$textarea,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('border-0 form-control chat-textarea'),
												$elm$html$Html$Attributes$placeholder('Napište doplňující zprávu'),
												$elm$html$Html$Events$onInput($author$project$Page$Case$SetDescription),
												$elm$html$Html$Events$onFocus(
												$author$project$Page$Case$SetFormSize(1))
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-group form-group-attachment')
									]),
								_List_fromArray(
									[
										function () {
										var _v0 = form.Y;
										if (!_v0.$) {
											var file = _v0.a;
											return A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('mdc-chip')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$i,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('material-icons mdc-chip__icon mdc-chip__icon--leading')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('attachment')
															])),
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('mdc-chip__primary-action')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('mdc-chip__text')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(file.dI)
																	]))
															])),
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('mdc-close')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('material-icons mdc-chip__icon mdc-chip__icon--trailing'),
																		$elm$html$Html$Attributes$tabindex(-1),
																		$elm$html$Html$Events$onClick($author$project$Page$Case$RemoveFile)
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('cancel')
																	]))
															]))
													]));
										} else {
											return A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('btn btn-attachment'),
														$elm$html$Html$Events$onClick($author$project$Page$Case$OpenFileClicked)
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$i,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('material-icons')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('attachment')
															])),
														$elm$html$Html$text('Přiložit soubor')
													]));
										}
									}()
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('chat-sent')
							]),
						_List_fromArray(
							[
								A2(
								$aforemny$material_components_web_elm$Material$Button$raised,
								A2(
									$aforemny$material_components_web_elm$Material$Button$setDisabled,
									!$author$project$Page$Case$canSubmitForm(form),
									$aforemny$material_components_web_elm$Material$Button$config),
								'Odeslat')
							]))
					])),
				form.ah ? $author$project$Page$Case$loader(40) : $elm$html$Html$text('')
			]));
};
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $elm$core$String$lines = _String_lines;
var $author$project$Page$Case$nl2br = function (str) {
	return A2(
		$elm$core$List$intersperse,
		A2($elm$html$Html$br, _List_Nil, _List_Nil),
		A2(
			$elm$core$List$map,
			$elm$html$Html$text,
			$elm$core$String$lines(str)));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$Page$Case$viewAvatar = function (author) {
	var _v0 = author.dd;
	if (!_v0.$) {
		var avatar = _v0.a;
		return A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src(avatar),
					$elm$html$Html$Attributes$class('rounded-circle avatar size-40'),
					$elm$html$Html$Attributes$alt('')
				]),
			_List_Nil);
	} else {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('avatar-initials avatar rounded-circle size-40'),
					$elm$html$Html$Attributes$alt('')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					A2($author$project$Type$Case$initials, author.dD, author.dI))
				]));
	}
};
var $author$project$Page$Case$FileDownload = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Case$NoOpInternal = {$: 0};
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Page$Case$viewFile = F2(
	function (downloadingFiles, file) {
		var isDownloadingFile = A2($elm$core$List$member, file, downloadingFiles);
		var _v0 = isDownloadingFile ? _Utils_Tuple2(
			$author$project$Page$Case$loader(20),
			$author$project$Page$Case$NoOpInternal) : _Utils_Tuple2(
			$elm$html$Html$text(''),
			$author$project$Page$Case$FileDownload(file));
		var fileLoader = _v0.a;
		var downloadMsg = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-chip'),
					$elm$html$Html$Events$onClick(downloadMsg)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-chip__ripple')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons mdc-chip__icon mdc-chip__icon--leading')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('attachment')
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mdc-chip__primary-action')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mdc-chip__text')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(file.dI)
								]))
						])),
					fileLoader
				]));
	});
var $author$project$Page$Case$viewHistoryItem = F3(
	function (timeNow, downloadingFiles, item) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id(
					$author$project$Type$Case$historyItemId(item.co)),
					$elm$html$Html$Attributes$class('case-history-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('case-history-item-header')
						]),
					_List_fromArray(
						[
							$author$project$Page$Case$viewAvatar(item.aZ),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('header-info')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('header-title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(item.aZ.dI)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('header-meta')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											A2($author$project$DateTime$format, item.dm, timeNow))
										]))
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('case-history-item-content message')
						]),
					$author$project$Page$Case$nl2br(item.bi)),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id(
							$author$project$Type$Case$historyItemFilesId(item.co)),
							$elm$html$Html$Attributes$class('case-history-item-files mdc-chip-set')
						]),
					A2(
						$elm$core$List$map,
						$author$project$Page$Case$viewFile(downloadingFiles),
						item.dw))
				]));
	});
var $author$project$Page$Case$viewCase = F4(
	function (caseDetail, form, timeNow, downloadingFiles) {
		var moduleContentAttributes = (form.ai === 1) ? _List_fromArray(
			[
				$elm$html$Html$Attributes$class('module-box-content'),
				$elm$html$Html$Events$onClick(
				$author$project$Page$Case$SetFormSize(0))
			]) : _List_fromArray(
			[
				$elm$html$Html$Attributes$class('module-box-content')
			]);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('case-detail module-box'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('chat-form-size-basic', !form.ai),
							_Utils_Tuple2('chat-form-size-expanded', form.ai === 1)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('case-header module-box-header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('module-box-header-inner')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('case-info')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h1,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('module-title case-title')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(caseDetail.ed)
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('case-status')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(caseDetail.d4)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('module-box-header-right case-date')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											A2($author$project$DateTime$format, caseDetail.c0, timeNow))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					moduleContentAttributes,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('module-box-column case-history-wrap')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('scrollbar')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('scrollbar-inner')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('module-detail')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('case-history')
																]),
															A2(
																$elm$core$List$map,
																A2($author$project$Page$Case$viewHistoryItem, timeNow, downloadingFiles),
																caseDetail.dy))
														]))
												]))
										]))
								]))
						])),
					$author$project$Page$Case$viewForm(form)
				]));
	});
var $author$project$Page$Case$view = function (_v0) {
	var caseDetail = _v0.dg;
	var form = _v0.az;
	var timeNow = _v0.J;
	var downloadingFiles = _v0.N;
	return A4($author$project$Page$Case$viewCase, caseDetail, form, timeNow, downloadingFiles);
};
var $author$project$Page$Home$view = F2(
	function (_v0, toMsg) {
		var cases = _v0.v;
		var timeNow = _v0.J;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('homepage-content')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$map,
					toMsg,
					A3($author$project$Cases$view, cases, $elm$core$Maybe$Nothing, timeNow))
				]));
	});
var $author$project$Page$NewCase$OpenFileClicked = {$: 2};
var $author$project$Page$NewCase$RemoveFile = {$: 5};
var $author$project$Page$NewCase$SetDescription = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$NewCase$SetSubject = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$NewCase$SubmitForm = {$: 6};
var $elm$html$Html$Attributes$autofocus = $elm$html$Html$Attributes$boolProperty('autofocus');
var $author$project$Page$NewCase$canSubmitForm = function (model) {
	return A2(
		$elm$core$List$all,
		$elm$core$Basics$not,
		_List_fromArray(
			[
				$elm$core$String$isEmpty(
				$elm$core$String$trim(model.ak)),
				$elm$core$String$isEmpty(
				$elm$core$String$trim(model.V)),
				model.ah
			]));
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $author$project$Page$NewCase$loader = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('loader-view w-100 h-100'),
			A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
			A2($elm$html$Html$Attributes$style, 'top', '0px'),
			A2($elm$html$Html$Attributes$style, 'left', '0px'),
			A2($elm$html$Html$Attributes$style, 'background', 'rgba(255,255,255,0.5)')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate'),
					A2($elm$html$Html$Attributes$style, 'width', '40px'),
					A2($elm$html$Html$Attributes$style, 'height', '40px')
				]),
			_List_fromArray(
				[$author$project$SvgObject$loader]))
		]));
var $elm$html$Html$Attributes$maxlength = function (n) {
	return A2(
		_VirtualDom_attribute,
		'maxlength',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Attributes$required = $elm$html$Html$Attributes$boolProperty('required');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Page$NewCase$viewForm = function (model) {
	return A2(
		$elm$html$Html$form,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-new-case'),
				$elm$html$Html$Events$onSubmit($author$project$Page$NewCase$SubmitForm)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$id('subject'),
								$elm$html$Html$Attributes$type_('text'),
								$elm$html$Html$Attributes$required(true),
								$elm$html$Html$Attributes$autofocus(true),
								$elm$html$Html$Attributes$value(model.ak),
								$elm$html$Html$Events$onInput($author$project$Page$NewCase$SetSubject),
								A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
								$elm$html$Html$Attributes$placeholder('Předmět požadavku'),
								$elm$html$Html$Attributes$maxlength(60)
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$textarea,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-control'),
								$elm$html$Html$Attributes$id('desc'),
								A2($elm$html$Html$Attributes$attribute, 'rows', '12'),
								$elm$html$Html$Attributes$required(true),
								$elm$html$Html$Events$onInput($author$project$Page$NewCase$SetDescription),
								$elm$html$Html$Attributes$placeholder('Popis požadavku')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(model.V)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group form-group-attachment')
					]),
				_List_fromArray(
					[
						function () {
						var _v0 = model.Y;
						if (!_v0.$) {
							var file = _v0.a;
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mdc-chip')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('material-icons mdc-chip__icon mdc-chip__icon--leading')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('attachment')
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mdc-chip__primary-action')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('mdc-chip__text')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(file.dI)
													]))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mdc-close')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('material-icons mdc-chip__icon mdc-chip__icon--trailing'),
														$elm$html$Html$Attributes$tabindex(-1),
														$elm$html$Html$Events$onClick($author$project$Page$NewCase$RemoveFile)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('cancel')
													]))
											]))
									]));
						} else {
							return A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('btn btn-attachment'),
										$elm$html$Html$Events$onClick($author$project$Page$NewCase$OpenFileClicked)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('material-icons')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('attachment')
											])),
										$elm$html$Html$text('Přiložit soubor')
									]));
						}
					}()
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-group')
					]),
				_List_fromArray(
					[
						A2(
						$aforemny$material_components_web_elm$Material$Button$raised,
						A2(
							$aforemny$material_components_web_elm$Material$Button$setDisabled,
							!$author$project$Page$NewCase$canSubmitForm(model),
							$aforemny$material_components_web_elm$Material$Button$config),
						'Odeslat')
					])),
				model.ah ? $author$project$Page$NewCase$loader : $elm$html$Html$text('')
			]));
};
var $author$project$Page$NewCase$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('new-case module-box')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('case-header module-box-header')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('module-box-header-inner')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h1,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('module-title')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Nový požadavek')
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('module-box-content')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('module-box-column')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('scrollbar')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('scrollbar-inner')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('module-detail')
													]),
												_List_fromArray(
													[
														$author$project$Page$NewCase$viewForm(model)
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Search$CaseSearch = 0;
var $author$project$Search$FileSearch = 2;
var $author$project$Search$MessageSearch = 1;
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {dB: index, dF: match, dS: number, d7: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$never = _Regex_never;
var $author$project$Search$regexOptions = {dh: true, dH: false};
var $author$project$Search$filterByQuery = F2(
	function (query, cases) {
		var regex = A2(
			$elm$core$Maybe$withDefault,
			$elm$regex$Regex$never,
			A2($elm$regex$Regex$fromStringWith, $author$project$Search$regexOptions, query));
		return A2(
			$elm$core$List$filter,
			function (item) {
				return A2($elm$regex$Regex$contains, regex, item.b9);
			},
			cases);
	});
var $author$project$Search$transformCasesToSearch = function (cases) {
	return A2(
		$elm$core$List$map,
		function (item) {
			return {a$: item.co, b9: item.ed, z: item.c0, P: $elm$core$Maybe$Nothing, ed: item.ed, bU: 0};
		},
		cases);
};
var $author$project$Search$transformFilesToSearch = function (cases) {
	return $elm$core$List$concat(
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (item) {
					return A2(
						$elm$core$List$map,
						function (history) {
							return A2(
								$elm$core$List$map,
								function (file) {
									return {
										a$: item.co,
										b9: file.dI,
										z: history.dm,
										P: $elm$core$Maybe$Just(history.co),
										ed: item.ed,
										bU: 2
									};
								},
								history.dw);
						},
						item.dy);
				},
				cases)));
};
var $author$project$Search$transformHistoryToSearch = function (cases) {
	return $elm$core$List$concat(
		A2(
			$elm$core$List$map,
			function (item) {
				return A2(
					$elm$core$List$map,
					function (history) {
						return {
							a$: item.co,
							b9: history.bi,
							z: history.dm,
							P: $elm$core$Maybe$Just(history.co),
							ed: item.ed,
							bU: 1
						};
					},
					item.dy);
			},
			cases));
};
var $author$project$Search$transformToSearch = function (cases) {
	return $elm$core$List$reverse(
		A2(
			$elm$core$List$sortBy,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.z;
				},
				$elm$time$Time$posixToMillis),
			$elm$core$List$concat(
				_List_fromArray(
					[
						$author$project$Search$transformCasesToSearch(cases),
						$author$project$Search$transformHistoryToSearch(cases),
						$author$project$Search$transformFilesToSearch(cases)
					]))));
};
var $author$project$Search$viewEmptyResult = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('search-empty')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Nebyly nalezeny žádné výsledky.')
				]))
		]));
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Search$onlyType = F2(
	function (type_, data) {
		return A2(
			$elm$core$List$filter,
			function (item) {
				return _Utils_eq(item.bU, type_);
			},
			data);
	});
var $aforemny$material_components_web_elm$Material$List$setDense = F2(
	function (dense, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{a7: dense});
	});
var $aforemny$material_components_web_elm$Material$List$setTwoLine = F2(
	function (twoLine, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{bT: twoLine});
	});
var $author$project$Search$Select = function (a) {
	return {$: 1, a: a};
};
var $author$project$Search$maxWordsAfter = 8;
var $author$project$Search$maxWordsBefore = 3;
var $author$project$Search$contentPartRegex = function (query) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		A2(
			$elm$regex$Regex$fromStringWith,
			$author$project$Search$regexOptions,
			A3(
				$elm$core$String$replace,
				'__MAX_AFTER__',
				$elm$core$String$fromInt($author$project$Search$maxWordsAfter),
				A3(
					$elm$core$String$replace,
					'__MAX_BEFORE__',
					$elm$core$String$fromInt($author$project$Search$maxWordsBefore),
					A3($elm$core$String$replace, '__QUERY__', query, '((?:[a-zA-Z0-9À-ž_]+\\W+){0,__MAX_BEFORE__}\\w*)(__QUERY__)(\\w*(?:\\W+[a-zA-Z0-9À-ž_]+){0,__MAX_AFTER__})')))));
};
var $elm$regex$Regex$find = _Regex_findAtMost(_Regex_infinity);
var $aforemny$material_components_web_elm$Material$List$Item$graphic = F2(
	function (additionalAttributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-list-item__graphic'),
				additionalAttributes),
			nodes);
	});
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $aforemny$material_components_web_elm$Material$List$Item$primaryText = F2(
	function (additionalAttributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-list-item__primary-text'),
				additionalAttributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$List$Item$secondaryText = F2(
	function (additionalAttributes, nodes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-list-item__secondary-text'),
				additionalAttributes),
			nodes);
	});
var $aforemny$material_components_web_elm$Material$List$Item$text = F2(
	function (additionalAttributes, _v0) {
		var primary = _v0.cC;
		var secondary = _v0.cP;
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('mdc-list-item__text'),
				additionalAttributes),
			_List_fromArray(
				[
					A2($aforemny$material_components_web_elm$Material$List$Item$primaryText, _List_Nil, primary),
					A2($aforemny$material_components_web_elm$Material$List$Item$secondaryText, _List_Nil, secondary)
				]));
	});
var $elm$core$String$words = _String_words;
var $author$project$Search$viewSearchItem = F4(
	function (type_, query, timeNow, item) {
		var truncateBefore = F2(
			function (maxWords, str) {
				return (_Utils_cmp(
					$elm$core$List$length(
						$elm$core$String$words(str)),
					maxWords) > -1) ? ('...' + str) : str;
			});
		var truncateAfter = F2(
			function (maxWords, str) {
				return (_Utils_cmp(
					$elm$core$List$length(
						$elm$core$String$words(str)),
					maxWords) > -1) ? (str + '...') : str;
			});
		var submatches = function (str) {
			return A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$elm$core$List$head(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.d7;
						},
						A2(
							$elm$regex$Regex$find,
							$author$project$Search$contentPartRegex(query),
							str))));
		};
		var highlightTruncated = function (str) {
			return A2(
				$elm$core$List$indexedMap,
				F2(
					function (index, maybeSubmatch) {
						if (!maybeSubmatch.$) {
							var submatch = maybeSubmatch.a;
							return (!index) ? $elm$html$Html$text(
								A2(truncateBefore, $author$project$Search$maxWordsBefore, submatch)) : ((index === 1) ? A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('highlight')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(submatch)
									])) : $elm$html$Html$text(
								A2(truncateAfter, $author$project$Search$maxWordsAfter, submatch)));
						} else {
							return $elm$html$Html$text('');
						}
					}),
				submatches(str));
		};
		var highlight = function (str) {
			return A2(
				$elm$core$List$indexedMap,
				F2(
					function (index, maybeSubmatch) {
						if (!maybeSubmatch.$) {
							var submatch = maybeSubmatch.a;
							return (index === 1) ? A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('highlight')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(submatch)
									])) : $elm$html$Html$text(submatch);
						} else {
							return $elm$html$Html$text('');
						}
					}),
				submatches(str));
		};
		switch (type_) {
			case 0:
				return A2(
					$aforemny$material_components_web_elm$Material$List$Item$listItem,
					A2(
						$aforemny$material_components_web_elm$Material$List$Item$setOnClick,
						$author$project$Search$Select(item),
						$aforemny$material_components_web_elm$Material$List$Item$config),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('search-item search-item-case')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$strong,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('search-item-title')
										]),
									highlight(item.ed))
								])),
							A2(
							$aforemny$material_components_web_elm$Material$List$Item$meta,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									A2($author$project$DateTime$format, item.z, timeNow))
								]))
						]));
			case 1:
				return A2(
					$aforemny$material_components_web_elm$Material$List$Item$listItem,
					A2(
						$aforemny$material_components_web_elm$Material$List$Item$setOnClick,
						$author$project$Search$Select(item),
						$aforemny$material_components_web_elm$Material$List$Item$config),
					_List_fromArray(
						[
							A2(
							$aforemny$material_components_web_elm$Material$List$Item$graphic,
							_List_Nil,
							_List_fromArray(
								[
									A2($aforemny$material_components_web_elm$Material$Icon$icon, _List_Nil, 'message')
								])),
							A2(
							$aforemny$material_components_web_elm$Material$List$Item$text,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('search-item search-item-message')
								]),
							{
								cC: _List_fromArray(
									[
										A2(
										$elm$html$Html$strong,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('search-item-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(item.ed)
											]))
									]),
								cP: _List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('search-item-content')
											]),
										highlightTruncated(item.b9))
									])
							}),
							A2(
							$aforemny$material_components_web_elm$Material$List$Item$meta,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									A2($author$project$DateTime$format, item.z, timeNow))
								]))
						]));
			default:
				return A2(
					$aforemny$material_components_web_elm$Material$List$Item$listItem,
					A2(
						$aforemny$material_components_web_elm$Material$List$Item$setOnClick,
						$author$project$Search$Select(item),
						$aforemny$material_components_web_elm$Material$List$Item$config),
					_List_fromArray(
						[
							A2(
							$aforemny$material_components_web_elm$Material$List$Item$graphic,
							_List_Nil,
							_List_fromArray(
								[
									A2($aforemny$material_components_web_elm$Material$Icon$icon, _List_Nil, 'attachment')
								])),
							A2(
							$aforemny$material_components_web_elm$Material$List$Item$text,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('search-item search-item-file')
								]),
							{
								cC: _List_fromArray(
									[
										A2(
										$elm$html$Html$strong,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('search-item-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(item.ed)
											]))
									]),
								cP: _List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('search-item-content')
											]),
										highlight(item.b9))
									])
							}),
							A2(
							$aforemny$material_components_web_elm$Material$List$Item$meta,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									A2($author$project$DateTime$format, item.z, timeNow))
								]))
						]));
		}
	});
var $author$project$Search$viewType = F5(
	function (type_, title, searchedCases, query, timeNow) {
		var seachedCasesByType = A2($author$project$Search$onlyType, type_, searchedCases);
		return $elm$core$List$isEmpty(seachedCasesByType) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h3,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('group-title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					A2(
					$aforemny$material_components_web_elm$Material$List$list,
					A2(
						$aforemny$material_components_web_elm$Material$List$setDense,
						true,
						A2($aforemny$material_components_web_elm$Material$List$setTwoLine, !(!type_), $aforemny$material_components_web_elm$Material$List$config)),
					A2(
						$elm$core$List$map,
						A3($author$project$Search$viewSearchItem, type_, query, timeNow),
						seachedCasesByType))
				]));
	});
var $aforemny$material_components_web_elm$Material$Elevation$z = function (n) {
	return $elm$html$Html$Attributes$class(
		'mdc-elevation--z' + $elm$core$String$fromInt(n));
};
var $aforemny$material_components_web_elm$Material$Elevation$z8 = $aforemny$material_components_web_elm$Material$Elevation$z(8);
var $author$project$Search$view = F3(
	function (_v0, cases, timeNow) {
		var query = _v0.Q;
		var isHidden = $elm$core$String$length(query) < 2;
		var drowerScrim = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mdc-drawer-scrim'),
					$elm$html$Html$Events$onClick(
					$author$project$Search$SetQuery(''))
				]),
			_List_Nil);
		return isHidden ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-result-wrap hidden')
				]),
			_List_fromArray(
				[drowerScrim])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-result-wrap'),
					$aforemny$material_components_web_elm$Material$Elevation$z8
				]),
			_List_fromArray(
				[
					drowerScrim,
					function () {
					var searchedCases = A2(
						$author$project$Search$filterByQuery,
						query,
						$author$project$Search$transformToSearch(cases));
					return $elm$core$List$isEmpty(searchedCases) ? A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('search-result h-100')
							]),
						_List_fromArray(
							[$author$project$Search$viewEmptyResult])) : A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('search-result')
							]),
						_List_fromArray(
							[
								A5($author$project$Search$viewType, 0, 'Případy', searchedCases, query, timeNow),
								A5($author$project$Search$viewType, 1, 'Zprávy', searchedCases, query, timeNow),
								A5($author$project$Search$viewType, 2, 'Soubory', searchedCases, query, timeNow)
							]));
				}()
				]));
	});
var $author$project$Page$Home$viewEmpty = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('homepage-content-welcome')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('homepage-content-welcome-inner')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('image')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src('/images/discussion.svg')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('meta')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Vítejte')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('description')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('S čím Vám právě teď můžeme pomoct?'),
									A2($elm$html$Html$br, _List_Nil, _List_Nil),
									$elm$html$Html$text('Zeptejte se a my odpovíme.')
								])),
							A2(
							$aforemny$material_components_web_elm$Material$Button$raised,
							A2(
								$aforemny$material_components_web_elm$Material$Button$setIcon,
								$elm$core$Maybe$Just('add'),
								A2(
									$aforemny$material_components_web_elm$Material$Button$setHref,
									$elm$core$Maybe$Just(
										$author$project$Route$routeToString($author$project$Route$NewCase)),
									$aforemny$material_components_web_elm$Material$Button$config)),
							'Nový požadavek')
						]))
				]))
		]));
var $author$project$Search$viewInput = F2(
	function (inputId, query) {
		return A2(
			$elm$html$Html$input,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id(inputId),
					A2($elm$html$Html$Attributes$attribute, 'aria-label', 'Search'),
					A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'off'),
					$elm$html$Html$Attributes$class('form-control border-0 w-100'),
					$elm$html$Html$Attributes$placeholder('Hledat zde...'),
					$elm$html$Html$Attributes$type_('search'),
					$elm$html$Html$Attributes$value(query),
					$elm$html$Html$Events$onInput($author$project$Search$SetQuery)
				]),
			_List_Nil);
	});
var $author$project$Search$viewForm = function (_v0) {
	var query = _v0.Q;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-group')
			]),
		_List_fromArray(
			[
				A2($author$project$Search$viewInput, 'search-input', query),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('search-icon')
					]),
				_List_fromArray(
					[
						A2($aforemny$material_components_web_elm$Material$Icon$icon, _List_Nil, 'search')
					]))
			]));
};
var $author$project$Search$CloseMobileForm = {$: 3};
var $author$project$Search$OpenMobileForm = {$: 2};
var $aforemny$material_components_web_elm$Material$IconButton$setOnClick = F2(
	function (onClick, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				bu: $elm$core$Maybe$Just(onClick)
			});
	});
var $author$project$Search$viewMobileForm = function (_v0) {
	var query = _v0.Q;
	var isMobileFormOpened = _v0.aD;
	return isMobileFormOpened ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-group')
			]),
		_List_fromArray(
			[
				A2($author$project$Search$viewInput, 'search-input-mobile', query),
				A2(
				$aforemny$material_components_web_elm$Material$IconButton$iconButton,
				A2($aforemny$material_components_web_elm$Material$IconButton$setOnClick, $author$project$Search$CloseMobileForm, $aforemny$material_components_web_elm$Material$IconButton$config),
				'close')
			])) : A2(
		$aforemny$material_components_web_elm$Material$IconButton$iconButton,
		A2($aforemny$material_components_web_elm$Material$IconButton$setOnClick, $author$project$Search$OpenMobileForm, $aforemny$material_components_web_elm$Material$IconButton$config),
		'search');
};
var $author$project$Main$viewPageLogged = F4(
	function (page, isLoading, _v0, user) {
		var cases = _v0.v;
		var casesLoaded = _v0.a0;
		var maybeRoute = _v0.ac;
		var search = _v0.I;
		var timeNow = _v0.J;
		var userMenuOpened = _v0.ap;
		var device = _v0.M;
		var viewSearch = A2(
			$elm$html$Html$map,
			$author$project$Main$SearchMsg,
			A3($author$project$Search$view, search, cases, timeNow));
		var arrowBack = function (isVisible) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('app-back'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('app-back-visible', isVisible && (!isLoading))
							]))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$author$project$Route$href($author$project$Route$Home)
							]),
						_List_fromArray(
							[
								A2($aforemny$material_components_web_elm$Material$IconButton$iconButton, $aforemny$material_components_web_elm$Material$IconButton$config, 'arrow_back')
							]))
					]));
		};
		var viewBackButton = function (isVisible) {
			if (!device) {
				return arrowBack(isVisible);
			} else {
				return $elm$html$Html$text('');
			}
		};
		var viewNavbar = function (backButtonVisible) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('d-flex w-100 align-items-center')
					]),
				_List_fromArray(
					[
						viewBackButton(backButtonVisible),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('app-logo')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$author$project$Route$href($author$project$Route$Home)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src('/images/logo.svg'),
												$elm$html$Html$Attributes$alt('Avogado')
											]),
										_List_Nil)
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('search-bar right-side-icon bg-transparent d-none d-md-block')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								$author$project$Main$SearchMsg,
								$author$project$Search$viewForm(search))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('search-bar-mobile d-block d-md-none')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								$author$project$Main$SearchMsg,
								$author$project$Search$viewMobileForm(search))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('user-profile'),
								$aforemny$material_components_web_elm$Material$Menu$surfaceAnchor
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('avatar avatar-initials rounded-circle size-40'),
										$elm$html$Html$Events$onClick($author$project$Main$MenuOpened)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Util$initials(user.dI))
									])),
								A2(
								$aforemny$material_components_web_elm$Material$Menu$menu,
								A2(
									$aforemny$material_components_web_elm$Material$Menu$setOnClose,
									$author$project$Main$MenuClosed,
									A2($aforemny$material_components_web_elm$Material$Menu$setOpen, userMenuOpened, $aforemny$material_components_web_elm$Material$Menu$config)),
								_List_fromArray(
									[
										A2(
										$aforemny$material_components_web_elm$Material$List$list,
										A2($aforemny$material_components_web_elm$Material$List$setWrapFocus, true, $aforemny$material_components_web_elm$Material$List$config),
										_List_fromArray(
											[
												A2(
												$aforemny$material_components_web_elm$Material$List$Item$listItem,
												A2(
													$aforemny$material_components_web_elm$Material$List$Item$setAttributes,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$target('_blank'),
															$elm$html$Html$Attributes$class('item-with-icon')
														]),
													A2(
														$aforemny$material_components_web_elm$Material$List$Item$setHref,
														$elm$core$Maybe$Just('https://moje.avogado.cz/index.php?route=account/edit'),
														$aforemny$material_components_web_elm$Material$List$Item$config)),
												_List_fromArray(
													[
														$elm$html$Html$text('Můj účet'),
														A2(
														$aforemny$material_components_web_elm$Material$List$Item$meta,
														_List_Nil,
														_List_fromArray(
															[
																A2($aforemny$material_components_web_elm$Material$Icon$icon, _List_Nil, 'arrow_right_alt')
															]))
													])),
												$aforemny$material_components_web_elm$Material$List$Divider$listItem($aforemny$material_components_web_elm$Material$List$Divider$config),
												A2(
												$aforemny$material_components_web_elm$Material$List$Item$listItem,
												A2($aforemny$material_components_web_elm$Material$List$Item$setOnClick, $author$project$Main$Logout, $aforemny$material_components_web_elm$Material$List$Item$config),
												_List_fromArray(
													[
														$elm$html$Html$text('Odhlásit')
													]))
											]))
									]))
							]))
					]));
		};
		var viewSidebar = casesLoaded ? ($elm$core$List$isEmpty(cases) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('chat-sidenav')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('chat-sidenav-main')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('module-side-header new-case-btn-wrap')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('module-logo')
										]),
									_List_fromArray(
										[
											arrowBack(true)
										]))
								]))
						]))
				])) : A2(
			$elm$html$Html$map,
			$author$project$Main$CasesMsg,
			A3($author$project$Cases$view, cases, maybeRoute, timeNow))) : $elm$html$Html$text('');
		switch (page.$) {
			case 0:
				return $elm$html$Html$text('');
			case 1:
				var subModel = page.a;
				if ($elm$core$List$isEmpty(subModel.v)) {
					return A5(
						$author$project$Layout$app,
						isLoading,
						viewNavbar(false),
						$elm$core$Maybe$Nothing,
						viewSearch,
						A2($elm$html$Html$map, $author$project$Main$HomeMsg, $author$project$Page$Home$viewEmpty));
				} else {
					if (!device) {
						return A5(
							$author$project$Layout$app,
							isLoading,
							viewNavbar(false),
							$elm$core$Maybe$Just(viewSidebar),
							viewSearch,
							A2($author$project$Page$Home$view, subModel, $author$project$Main$CasesMsg));
					} else {
						return A5(
							$author$project$Layout$app,
							isLoading,
							viewNavbar(false),
							$elm$core$Maybe$Just(viewSidebar),
							viewSearch,
							$elm$html$Html$text(''));
					}
				}
			case 2:
				var subModel = page.a;
				return A5(
					$author$project$Layout$app,
					isLoading,
					viewNavbar(true),
					$elm$core$Maybe$Just(viewSidebar),
					viewSearch,
					A2(
						$elm$html$Html$map,
						$author$project$Main$CaseMsg,
						$author$project$Page$Case$view(subModel)));
			case 3:
				var subModel = page.a;
				return A5(
					$author$project$Layout$app,
					isLoading,
					viewNavbar(true),
					$elm$core$Maybe$Just(viewSidebar),
					viewSearch,
					A2(
						$elm$html$Html$map,
						$author$project$Main$NewCaseMsg,
						$author$project$Page$NewCase$view(subModel)));
			case 4:
				var subModel = page.a;
				return isLoading ? A5(
					$author$project$Layout$app,
					isLoading,
					viewNavbar(false),
					$elm$core$Maybe$Just(viewSidebar),
					viewSearch,
					$elm$html$Html$text('')) : $elm$html$Html$text('');
			default:
				return $elm$html$Html$text('');
		}
	});
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$Layout$login = function (content) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('app-site'),
				$elm$html$Html$Attributes$class('layout-login')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('app-main')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('app-container')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('app-main-container')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$main_,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('app-main-content-wrapper')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('app-main-content')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('app-wrapper h-100')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('login-container d-flex align-items-center')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('login-content animated slideInUpTiny animation-duration-3')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$div,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('login-header mb-4')
																					]),
																				_List_fromArray(
																					[
																						A2(
																						$elm$html$Html$a,
																						_List_fromArray(
																							[
																								$elm$html$Html$Attributes$class('app-logo'),
																								$elm$html$Html$Attributes$title('Avogado'),
																								$author$project$Route$href($author$project$Route$Home)
																							]),
																						_List_fromArray(
																							[
																								A2(
																								$elm$html$Html$img,
																								_List_fromArray(
																									[
																										$elm$html$Html$Attributes$src('/images/logo.svg'),
																										$elm$html$Html$Attributes$alt('Avogado'),
																										$elm$html$Html$Attributes$title('Avogado')
																									]),
																								_List_Nil)
																							]))
																					])),
																				content
																			]))
																	]))
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$ForgotPassword$SetEmail = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$ForgotPassword$SubmitForm = {$: 1};
var $author$project$MaterialOverwritten$TextField$Config = $elm$core$Basics$identity;
var $author$project$MaterialOverwritten$TextField$config = {ar: _List_Nil, av: false, Z: false, aa: $elm$core$Maybe$Nothing, aE: $elm$core$Maybe$Nothing, bh: $elm$core$Maybe$Nothing, aH: $elm$core$Maybe$Nothing, bk: $elm$core$Maybe$Nothing, aI: $elm$core$Maybe$Nothing, bt: $elm$core$Maybe$Nothing, bw: $elm$core$Maybe$Nothing, bC: $elm$core$Maybe$Nothing, aO: $elm$core$Maybe$Nothing, bG: false, bN: $elm$core$Maybe$Nothing, aT: $elm$core$Maybe$Nothing, bU: $elm$core$Maybe$Nothing, bW: true, aU: $elm$core$Maybe$Nothing};
var $author$project$Page$ForgotPassword$errors = function (_v0) {
	var error = _v0.cc;
	return A2(
		$elm$core$List$filter,
		A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
		_List_fromArray(
			[error]));
};
var $author$project$MaterialOverwritten$TextField$disabledCs = function (_v0) {
	var disabled = _v0.av;
	return disabled ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--disabled')) : $elm$core$Maybe$Nothing;
};
var $author$project$MaterialOverwritten$TextField$disabledProp = function (_v0) {
	var disabled = _v0.av;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'disabled',
			$elm$json$Json$Encode$bool(disabled)));
};
var $author$project$MaterialOverwritten$TextField$fullwidthCs = function (_v0) {
	var fullwidth = _v0.Z;
	return fullwidth ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--fullwidth')) : $elm$core$Maybe$Nothing;
};
var $author$project$MaterialOverwritten$TextField$ariaLabelAttr = function (_v0) {
	var fullwidth = _v0.Z;
	var placeholder = _v0.aO;
	var label = _v0.aa;
	return fullwidth ? A2(
		$elm$core$Maybe$map,
		$elm$html$Html$Attributes$attribute('aria-label'),
		label) : $elm$core$Maybe$Nothing;
};
var $author$project$MaterialOverwritten$TextField$changeHandler = function (_v0) {
	var onChange = _v0.bt;
	return A2(
		$elm$core$Maybe$map,
		function (f) {
			return A2(
				$elm$html$Html$Events$on,
				'change',
				A2($elm$json$Json$Decode$map, f, $elm$html$Html$Events$targetValue));
		},
		onChange);
};
var $author$project$MaterialOverwritten$TextField$inputCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-text-field__input'));
var $author$project$MaterialOverwritten$TextField$inputHandler = function (_v0) {
	var onInput = _v0.bw;
	return A2($elm$core$Maybe$map, $elm$html$Html$Events$onInput, onInput);
};
var $author$project$MaterialOverwritten$TextField$maxLengthAttr = function (_v0) {
	var maxLength = _v0.aH;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Attributes$attribute('maxLength'),
			$elm$core$String$fromInt),
		maxLength);
};
var $author$project$MaterialOverwritten$TextField$minLengthAttr = function (_v0) {
	var minLength = _v0.aI;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Attributes$attribute('minLength'),
			$elm$core$String$fromInt),
		minLength);
};
var $author$project$MaterialOverwritten$TextField$placeholderAttr = function (_v0) {
	var placeholder = _v0.aO;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$placeholder, placeholder);
};
var $author$project$MaterialOverwritten$TextField$typeAttr = function (_v0) {
	var type_ = _v0.bU;
	return A2($elm$core$Maybe$map, $elm$html$Html$Attributes$type_, type_);
};
var $author$project$MaterialOverwritten$TextField$inputElt = function (config_) {
	var additionalAttributes = config_.ar;
	return A2(
		$elm$html$Html$input,
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						$author$project$MaterialOverwritten$TextField$inputCs,
						$author$project$MaterialOverwritten$TextField$typeAttr(config_),
						$author$project$MaterialOverwritten$TextField$ariaLabelAttr(config_),
						$author$project$MaterialOverwritten$TextField$placeholderAttr(config_),
						$author$project$MaterialOverwritten$TextField$inputHandler(config_),
						$author$project$MaterialOverwritten$TextField$changeHandler(config_),
						$author$project$MaterialOverwritten$TextField$minLengthAttr(config_),
						$author$project$MaterialOverwritten$TextField$maxLengthAttr(config_)
					])),
			additionalAttributes),
		_List_Nil);
};
var $author$project$MaterialOverwritten$TextField$labelElt = function (_v0) {
	var label = _v0.aa;
	var value = _v0.aU;
	var floatingLabelFloatAboveCs = 'mdc-floating-label--float-above';
	var floatingLabelCs = 'mdc-floating-label';
	if (!label.$) {
		var str = label.a;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					(A2($elm$core$Maybe$withDefault, '', value) !== '') ? $elm$html$Html$Attributes$class(floatingLabelCs + (' ' + floatingLabelFloatAboveCs)) : $elm$html$Html$Attributes$class(floatingLabelCs),
					A2(
					$elm$html$Html$Attributes$property,
					'foucClassNames',
					A2(
						$elm$json$Json$Encode$list,
						$elm$json$Json$Encode$string,
						_List_fromArray(
							[floatingLabelFloatAboveCs])))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(str)
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$MaterialOverwritten$TextField$leadingIconElt = function (_v0) {
	var leadingIcon = _v0.aE;
	if (leadingIcon.$ === 1) {
		return _List_Nil;
	} else {
		var html = leadingIcon.a;
		return _List_fromArray(
			[html]);
	}
};
var $author$project$MaterialOverwritten$TextField$lineRippleElt = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-line-ripple')
		]),
	_List_Nil);
var $author$project$MaterialOverwritten$TextField$maxLengthProp = function (_v0) {
	var maxLength = _v0.aH;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'maxLength',
			$elm$json$Json$Encode$int(
				A2($elm$core$Maybe$withDefault, -1, maxLength))));
};
var $author$project$MaterialOverwritten$TextField$maxProp = function (_v0) {
	var max = _v0.bh;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'max',
			$elm$json$Json$Encode$string(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($elm$core$Maybe$map, $elm$core$String$fromInt, max)))));
};
var $author$project$MaterialOverwritten$TextField$minLengthProp = function (_v0) {
	var minLength = _v0.aI;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'minLength',
			$elm$json$Json$Encode$int(
				A2($elm$core$Maybe$withDefault, -1, minLength))));
};
var $author$project$MaterialOverwritten$TextField$minProp = function (_v0) {
	var min = _v0.bk;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'min',
			$elm$json$Json$Encode$string(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($elm$core$Maybe$map, $elm$core$String$fromInt, min)))));
};
var $author$project$MaterialOverwritten$TextField$noLabelCs = function (_v0) {
	var label = _v0.aa;
	return _Utils_eq(label, $elm$core$Maybe$Nothing) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--no-label')) : $elm$core$Maybe$Nothing;
};
var $author$project$MaterialOverwritten$TextField$notchedOutlineLeadingElt = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-notched-outline__leading')
		]),
	_List_Nil);
var $author$project$MaterialOverwritten$TextField$notchedOutlineNotchElt = function (config_) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-notched-outline__notch')
			]),
		_List_fromArray(
			[
				$author$project$MaterialOverwritten$TextField$labelElt(config_)
			]));
};
var $author$project$MaterialOverwritten$TextField$notchedOutlineTrailingElt = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('mdc-notched-outline__trailing')
		]),
	_List_Nil);
var $author$project$MaterialOverwritten$TextField$notchedOutlineElt = function (config_) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('mdc-notched-outline')
			]),
		_List_fromArray(
			[
				$author$project$MaterialOverwritten$TextField$notchedOutlineLeadingElt,
				$author$project$MaterialOverwritten$TextField$notchedOutlineNotchElt(config_),
				$author$project$MaterialOverwritten$TextField$notchedOutlineTrailingElt
			]));
};
var $author$project$MaterialOverwritten$TextField$outlinedCs = function (outlined_) {
	return outlined_ ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--outlined')) : $elm$core$Maybe$Nothing;
};
var $author$project$MaterialOverwritten$TextField$patternProp = function (_v0) {
	var pattern = _v0.bC;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'pattern',
			$elm$json$Json$Encode$string(
				A2($elm$core$Maybe$withDefault, '', pattern))));
};
var $author$project$MaterialOverwritten$TextField$requiredProp = function (_v0) {
	var required = _v0.bG;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'required',
			$elm$json$Json$Encode$bool(required)));
};
var $author$project$MaterialOverwritten$TextField$rootCs = $elm$core$Maybe$Just(
	$elm$html$Html$Attributes$class('mdc-text-field'));
var $author$project$MaterialOverwritten$TextField$stepProp = function (_v0) {
	var step = _v0.bN;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'step',
			$elm$json$Json$Encode$string(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($elm$core$Maybe$map, $elm$core$String$fromInt, step)))));
};
var $author$project$MaterialOverwritten$TextField$trailingIconElt = function (_v0) {
	var trailingIcon = _v0.aT;
	if (trailingIcon.$ === 1) {
		return _List_Nil;
	} else {
		var html = trailingIcon.a;
		return _List_fromArray(
			[html]);
	}
};
var $author$project$MaterialOverwritten$TextField$validProp = function (_v0) {
	var valid = _v0.bW;
	return $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$Attributes$property,
			'valid',
			$elm$json$Json$Encode$bool(valid)));
};
var $author$project$MaterialOverwritten$TextField$valueProp = function (_v0) {
	var value = _v0.aU;
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$Attributes$property('value'),
			$elm$json$Json$Encode$string),
		value);
};
var $author$project$MaterialOverwritten$TextField$withLeadingIconCs = function (_v0) {
	var leadingIcon = _v0.aE;
	return (!_Utils_eq(leadingIcon, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--with-leading-icon')) : $elm$core$Maybe$Nothing;
};
var $author$project$MaterialOverwritten$TextField$withTrailingIconCs = function (_v0) {
	var trailingIcon = _v0.aT;
	return (!_Utils_eq(trailingIcon, $elm$core$Maybe$Nothing)) ? $elm$core$Maybe$Just(
		$elm$html$Html$Attributes$class('mdc-text-field--with-trailing-icon')) : $elm$core$Maybe$Nothing;
};
var $author$project$MaterialOverwritten$TextField$textField = F2(
	function (outlined_, config_) {
		var additionalAttributes = config_.ar;
		var fullwidth = config_.Z;
		return A3(
			$elm$html$Html$node,
			'mdc-text-field',
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$author$project$MaterialOverwritten$TextField$rootCs,
							$author$project$MaterialOverwritten$TextField$noLabelCs(config_),
							$author$project$MaterialOverwritten$TextField$outlinedCs(outlined_),
							$author$project$MaterialOverwritten$TextField$fullwidthCs(config_),
							$author$project$MaterialOverwritten$TextField$disabledCs(config_),
							$author$project$MaterialOverwritten$TextField$withLeadingIconCs(config_),
							$author$project$MaterialOverwritten$TextField$withTrailingIconCs(config_),
							$author$project$MaterialOverwritten$TextField$valueProp(config_),
							$author$project$MaterialOverwritten$TextField$disabledProp(config_),
							$author$project$MaterialOverwritten$TextField$requiredProp(config_),
							$author$project$MaterialOverwritten$TextField$validProp(config_),
							$author$project$MaterialOverwritten$TextField$patternProp(config_),
							$author$project$MaterialOverwritten$TextField$minLengthProp(config_),
							$author$project$MaterialOverwritten$TextField$maxLengthProp(config_),
							$author$project$MaterialOverwritten$TextField$minProp(config_),
							$author$project$MaterialOverwritten$TextField$maxProp(config_),
							$author$project$MaterialOverwritten$TextField$stepProp(config_)
						])),
				additionalAttributes),
			$elm$core$List$concat(
				_List_fromArray(
					[
						$author$project$MaterialOverwritten$TextField$leadingIconElt(config_),
						fullwidth ? (outlined_ ? _List_fromArray(
						[
							$author$project$MaterialOverwritten$TextField$inputElt(config_),
							$author$project$MaterialOverwritten$TextField$notchedOutlineElt(config_)
						]) : _List_fromArray(
						[
							$author$project$MaterialOverwritten$TextField$inputElt(config_),
							$author$project$MaterialOverwritten$TextField$labelElt(config_),
							$author$project$MaterialOverwritten$TextField$lineRippleElt
						])) : (outlined_ ? _List_fromArray(
						[
							$author$project$MaterialOverwritten$TextField$inputElt(config_),
							$author$project$MaterialOverwritten$TextField$notchedOutlineElt(config_)
						]) : _List_fromArray(
						[
							$author$project$MaterialOverwritten$TextField$inputElt(config_),
							$author$project$MaterialOverwritten$TextField$labelElt(config_),
							$author$project$MaterialOverwritten$TextField$lineRippleElt
						])),
						$author$project$MaterialOverwritten$TextField$trailingIconElt(config_)
					])));
	});
var $author$project$MaterialOverwritten$TextField$filled = function (config_) {
	return A2($author$project$MaterialOverwritten$TextField$textField, false, config_);
};
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $author$project$Page$ForgotPassword$loader = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('loader-view w-100 h-100'),
			A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
			A2($elm$html$Html$Attributes$style, 'top', '0px'),
			A2($elm$html$Html$Attributes$style, 'left', '0px'),
			A2($elm$html$Html$Attributes$style, 'background', 'rgba(255,255,255,0.5)')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate'),
					A2($elm$html$Html$Attributes$style, 'width', '40px'),
					A2($elm$html$Html$Attributes$style, 'height', '40px')
				]),
			_List_fromArray(
				[$author$project$SvgObject$loader]))
		]));
var $author$project$MaterialOverwritten$TextField$setAttributes = F2(
	function (additionalAttributes, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{ar: additionalAttributes});
	});
var $author$project$MaterialOverwritten$TextField$setFullwidth = F2(
	function (fullwidth, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{Z: fullwidth});
	});
var $author$project$MaterialOverwritten$TextField$setLabel = F2(
	function (label, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{aa: label});
	});
var $author$project$MaterialOverwritten$TextField$setOnInput = F2(
	function (onInput, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{
				bw: $elm$core$Maybe$Just(onInput)
			});
	});
var $author$project$MaterialOverwritten$TextField$setRequired = F2(
	function (required, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{bG: required});
	});
var $author$project$MaterialOverwritten$TextField$setType = F2(
	function (type_, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{bU: type_});
	});
var $author$project$MaterialOverwritten$TextField$setValue = F2(
	function (value, _v0) {
		var config_ = _v0;
		return _Utils_update(
			config_,
			{aU: value});
	});
var $author$project$Page$ForgotPassword$view = function (model) {
	var email = model.X;
	var textFieldConfig = function (autofocus) {
		return A2(
			$author$project$MaterialOverwritten$TextField$setAttributes,
			autofocus ? _List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'autofocus', '')
				]) : _List_Nil,
			A2(
				$author$project$MaterialOverwritten$TextField$setFullwidth,
				true,
				A2($author$project$MaterialOverwritten$TextField$setRequired, true, $author$project$MaterialOverwritten$TextField$config)));
	};
	var emailAutoFocus = $elm$core$String$isEmpty(
		_Utils_ap(email, model.cc));
	var allErrors = $author$project$Page$ForgotPassword$errors(model);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('login-form'),
				A2($elm$html$Html$Attributes$style, 'position', 'relative')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Zapomněli jste heslo?')
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Vyplňte Váš registrační e-mail a stiskněte tlačítko pro reset hesla. Následně Vám bude zaslán e-mail s dalším postupem.')
					])),
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$Page$ForgotPassword$SubmitForm)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb-4')
							]),
						_List_fromArray(
							[
								$author$project$MaterialOverwritten$TextField$filled(
								A2(
									$author$project$MaterialOverwritten$TextField$setOnInput,
									$author$project$Page$ForgotPassword$SetEmail,
									A2(
										$author$project$MaterialOverwritten$TextField$setValue,
										$elm$core$Maybe$Just(email),
										A2(
											$author$project$MaterialOverwritten$TextField$setType,
											$elm$core$Maybe$Just('email'),
											A2(
												$author$project$MaterialOverwritten$TextField$setLabel,
												$elm$core$Maybe$Just('E-mailová adresa'),
												textFieldConfig(emailAutoFocus))))))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('login-buttons d-flex justify-content-between align-items-center')
							]),
						_List_fromArray(
							[
								A2($aforemny$material_components_web_elm$Material$Button$raised, $aforemny$material_components_web_elm$Material$Button$config, 'Resetovat heslo'),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$author$project$Route$href($author$project$Route$Login)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Přihlásit se')
									]))
							])),
						$elm$core$List$isEmpty(allErrors) ? $elm$html$Html$text('') : A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mt-3 mb-3 invalid-feedback d-block')
							]),
						A2(
							$elm$core$List$map,
							function (error) {
								return A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(error)
										]));
							},
							allErrors))
					])),
				model.ah ? $author$project$Page$ForgotPassword$loader : $elm$html$Html$text('')
			]));
};
var $author$project$Page$Login$SetEmail = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Login$SetPassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Login$SubmitForm = {$: 2};
var $author$project$Page$Login$errors = function (_v0) {
	var errorEmail = _v0.ax;
	var errorPassword = _v0.ay;
	var error = _v0.cc;
	return A2(
		$elm$core$List$filter,
		A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
		_List_fromArray(
			[errorEmail, errorPassword, error]));
};
var $author$project$Page$Login$loader = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('loader-view w-100 h-100'),
			A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
			A2($elm$html$Html$Attributes$style, 'top', '0px'),
			A2($elm$html$Html$Attributes$style, 'left', '0px'),
			A2($elm$html$Html$Attributes$style, 'background', 'rgba(255,255,255,0.5)')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate'),
					A2($elm$html$Html$Attributes$style, 'width', '40px'),
					A2($elm$html$Html$Attributes$style, 'height', '40px')
				]),
			_List_fromArray(
				[$author$project$SvgObject$loader]))
		]));
var $author$project$Page$Login$view = function (model) {
	var email = model.X;
	var password = model.ag;
	var textFieldConfig = function (autofocus) {
		return A2(
			$author$project$MaterialOverwritten$TextField$setAttributes,
			autofocus ? _List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'autofocus', '')
				]) : _List_Nil,
			A2(
				$author$project$MaterialOverwritten$TextField$setFullwidth,
				true,
				A2($author$project$MaterialOverwritten$TextField$setRequired, true, $author$project$MaterialOverwritten$TextField$config)));
	};
	var emailAutoFocus = $elm$core$String$isEmpty(
		_Utils_ap(email, model.ax));
	var passwordAutoFocus = (!emailAutoFocus) && $elm$core$String$isEmpty(
		_Utils_ap(password, model.ay));
	var allErrors = $author$project$Page$Login$errors(model);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('login-form'),
				A2($elm$html$Html$Attributes$style, 'position', 'relative')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$form,
				_List_fromArray(
					[
						$elm$html$Html$Events$onSubmit($author$project$Page$Login$SubmitForm)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mb-4')
							]),
						_List_fromArray(
							[
								$author$project$MaterialOverwritten$TextField$filled(
								A2(
									$author$project$MaterialOverwritten$TextField$setOnInput,
									$author$project$Page$Login$SetEmail,
									A2(
										$author$project$MaterialOverwritten$TextField$setValue,
										$elm$core$Maybe$Just(email),
										A2(
											$author$project$MaterialOverwritten$TextField$setType,
											$elm$core$Maybe$Just('email'),
											A2(
												$author$project$MaterialOverwritten$TextField$setLabel,
												$elm$core$Maybe$Just('E-mailová adresa'),
												textFieldConfig(emailAutoFocus)))))),
								$author$project$MaterialOverwritten$TextField$filled(
								A2(
									$author$project$MaterialOverwritten$TextField$setOnInput,
									$author$project$Page$Login$SetPassword,
									A2(
										$author$project$MaterialOverwritten$TextField$setValue,
										$elm$core$Maybe$Just(password),
										A2(
											$author$project$MaterialOverwritten$TextField$setType,
											$elm$core$Maybe$Just('password'),
											A2(
												$author$project$MaterialOverwritten$TextField$setLabel,
												$elm$core$Maybe$Just('Heslo'),
												textFieldConfig(passwordAutoFocus))))))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('login-buttons d-flex justify-content-between align-items-center')
							]),
						_List_fromArray(
							[
								A2($aforemny$material_components_web_elm$Material$Button$raised, $aforemny$material_components_web_elm$Material$Button$config, 'Přihlásit se'),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('https://moje.avogado.cz/index.php?route=account/forgotten')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Zapomněli jste heslo?')
									]))
							])),
						$elm$core$List$isEmpty(allErrors) ? $elm$html$Html$text('') : A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mt-3 mb-3 invalid-feedback d-block')
							]),
						A2(
							$elm$core$List$map,
							function (error) {
								return A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(error)
										]));
							},
							allErrors))
					])),
				model.ah ? $author$project$Page$Login$loader : $elm$html$Html$text('')
			]));
};
var $author$project$Main$viewPageNotLogged = function (page) {
	switch (page.$) {
		case 0:
			return $elm$html$Html$text('');
		case 4:
			var subModel = page.a;
			return $author$project$Layout$login(
				A2(
					$elm$html$Html$map,
					$author$project$Main$LoginMsg,
					$author$project$Page$Login$view(subModel)));
		case 5:
			var subModel = page.a;
			return $author$project$Layout$login(
				A2(
					$elm$html$Html$map,
					$author$project$Main$ForgotPasswordMsg,
					$author$project$Page$ForgotPassword$view(subModel)));
		default:
			return $author$project$Layout$login(
				A2(
					$elm$html$Html$map,
					$author$project$Main$LoginMsg,
					$author$project$Page$Login$view($author$project$Page$Login$initModel)));
	}
};
var $author$project$Main$viewPage = F3(
	function (page, isLoading, model) {
		var _v0 = model.m;
		if (!_v0.$) {
			var user = _v0.a;
			return A4($author$project$Main$viewPageLogged, page, isLoading, model, user);
		} else {
			return $author$project$Main$viewPageNotLogged(page);
		}
	});
var $author$project$Main$view = function (model) {
	return {
		b5: _List_fromArray(
			[
				function () {
				var _v0 = model.g;
				if (!_v0.$) {
					var page = _v0.a;
					return A3($author$project$Main$viewPage, page, false, model);
				} else {
					var page = _v0.a;
					return A3($author$project$Main$viewPage, page, true, model);
				}
			}(),
				$author$project$Main$viewDialog(model.au),
				$author$project$Main$viewErrorDialog(model.aw)
			]),
		ed: $author$project$Main$pageTitle(model.g)
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{dC: $author$project$Main$init, dV: $author$project$Main$ChangedUrl, dW: $author$project$Main$ClickedLink, d8: $author$project$Main$subscriptions, ef: $author$project$Main$update, eg: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (width) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (user) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (timeNow) {
							return $elm$json$Json$Decode$succeed(
								{J: timeNow, bV: user, bY: width});
						},
						A2($elm$json$Json$Decode$field, 'timeNow', $elm$json$Json$Decode$int));
				},
				A2(
					$elm$json$Json$Decode$field,
					'user',
					$elm$json$Json$Decode$oneOf(
						_List_fromArray(
							[
								$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
								A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
							]))));
		},
		A2($elm$json$Json$Decode$field, 'width', $elm$json$Json$Decode$int)))(0)}});}(this));