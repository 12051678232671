
// import './assets/vendors/style'

import './styles/theme.scss';
import './styles/material.scss';
import './styles/main.scss';

import { Elm } from './elm/Main.elm';
import * as serviceWorker from './serviceWorker';

// imported material design JS for elm package "aforemny/material-components-web-elm"
import "./material-components-web-elm.js"; // this is cutom modification of commented file bellow (there was problems with TextField attributes - pattern, min, max) - marked with: *** CUSTOM MODIFICATION ***
// import "material-components-web-elm/dist/material-components-web-elm.js";

// The trick to viewport units on mobile (https://css-tricks.com/the-trick-to-viewport-units-on-mobile/)
import "./vh.js"

var whenIsLocalStorageEnabled = (localStorageCode) => {
  try {
    localStorageCode()
  } catch (e) {
    console.log("localStorage is not enabled.");
  }
}

var storageKey = "store";
var flags = null;

whenIsLocalStorageEnabled(() => {
  flags = {
    user: localStorage.getItem(storageKey),
    timeNow: Date.now(),
    width: window.innerWidth
  }
});

var app = Elm.Main.init({
  flags: flags,
  node: document.getElementById('root')
});

app.ports.storeCache.subscribe(function (val) {
  whenIsLocalStorageEnabled(() => {
    var strVal = JSON.stringify(val)
    if (val === null) {
      localStorage.removeItem(storageKey);
    } else {
      localStorage.setItem(storageKey, strVal);
    }
    // Report that the new session was stored successfully.
    setTimeout(function () { app.ports.onStoreChange.send(strVal); }, 0);
  });
});

var contentScrollbarSelector = ".module-box-content .scrollbar-inner";

app.ports.scrollToElement.subscribe(function (elementId) {
  var counter = 0;
  var scrollToElement = () => {
    setTimeout(() => {
      var element = document.getElementById(elementId);
      if (element) {
        document.querySelector(contentScrollbarSelector).scrollTop = element.offsetTop - 10;
      } else if (counter < 10) {
        scrollToElement()
      }
      counter++;
    }, 10);
  };
  scrollToElement();
});

app.ports.scrollTop.subscribe(function () {
  var scrollableContainer = document.querySelector(contentScrollbarSelector);
  if (scrollableContainer) {
    scrollableContainer.scrollTop = 0
  }
});

// Whenever localStorage changes in another tab, report it if necessary.
window.addEventListener("storage", function (event) {
  whenIsLocalStorageEnabled(() => {
    if (event.storageArea === localStorage && event.key === storageKey) {
      app.ports.onStoreChange.send(event.newValue);
    }
  });
}, false);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// Install app to home screen
let deferredPrompt;
window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent the mini-infobar from appearing on mobile
  // e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Update UI notify the user they can install the PWA
  // showInstallPromotion();
});